<script type="text/javascript"></script>
<template>
  <b-modal id='objectivemodal' v-model="isshown" size="lg" no-close-on-backdrop no-close-on-esc hide-footer @shown="modalshown" @hidden="modalhidden" >
    <template v-slot:modal-header>
      <h3 class="modal-title">{{objective.name}}</h3>
      <button type="button" aria-label="Close" @click="isshown=false;" class="close"><span aria-hidden="true">&times;</span></button>
    </template>  

    <objectivesCard :activeCockpit="cockpit.isactive" ref="objectivedata1" :objective="objective" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @cockpitreload="reloadData" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard>

  </b-modal>


</template>

<script>

  import Cockpitsapi from '@/services/api/cockpits'


  import objectivesCardfull from '@/views/cockpit/components/objectivesCardfull'

  export default {
    name:'objectiveModal',
    props:{
      cockpit:Object,
      token:String,
      loggedinuser:Object,
      shown:Boolean,
      objectiveid:String,
      indicatorid:String,
      activeCockpit:Boolean
    },
    data(){
      return{
        isshown : false,
        activeidicator:'',
        objective : {},
        swiperwrapper: null,
        swiper: null,
        cockpitcursors: [],
        perpage: 10,
        activetab:null,
        tables:null,
        objectivemembers : [],
        showsetaktivemodale:false,
        showinstruction : false,
        showmore : false,
        showobjectivesettings : false,
        addstatusindicator : false,
        calculatedindexvalue : 0,

        loaded:false
      }
    },
    watch:{
      shown : function( e ){
        this.isshown = e
      }
    },

    components:{objectivesCardfull},

    mounted(){

      //alert('cccccccccccc')

    },

    methods:{

      modalshown(){

        this.openobjective()

      },


      requestaccess(){},
      openmeasureinmodal(){},
      objectivesettings(){},
      addstatus(){},
      makeactive(){},
      loadmemberlist(){},
      showobjectivedescriptionmodal(){},
      reloadData(){},

      objectiveloaded(){


      },

      openobjective(){

        //console.log( this.indicatorid )

        let self = this 
        Cockpitsapi.Objectiveview( this.objectiveid, this.token)
        .then( function(response){

          if( response.data.ok){
            self.objective = response.data.measure
            console.log(response.data)
          }

        })
        .catch(error => console.log(error))
        .finally( () => {

        })


      },

      modalhidden(){
       // bus.$off( 'onopenmeasureinmodal' )
       this.$emit('onmodalhidden')
       this.isshown = false
     },

     loadcockpit( callback ){

     }

   }

        }

      </script>