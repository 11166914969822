<script type="text/javascript"></script>

<template>

	<div class="w-50">

		<div class="m-2">
		<addtojira :renderButton="'import'" @onjirasignin="onjirasignin" :key="connectkey"></addtojira>
		</div>
<div class="list-group w-auto">



  <li class="list-group-item list-group-item-action d-flex mb-2 border-top" aria-current="true" :key="key" v-for="( jiralink, key) in jiralinks" >
    

    <div class="flex-shrink-0 pr-2"> <i class="fab fa-jira" style="font-size:25px;"></i> </div>
    <div class="d-flex gap-2 w-100 justify-content-between">
      <div>
        <h3 class="mb-0">{{ payloadkey( jiralink.payload) }}</h3>
        <a :href="payloadurl( jiralink.payload)" class="mb-0 opacity-75" target="_blank">{{ payloadurl( jiralink.payload) }}</a>
        <p><a href="#" class="text-danger">Delete</a></p>
      </div>
      <small class="opacity-50 text-nowrap">{{ timeago(jiralink.audit.modifieddate)  }}</small>

    </div>
  </li>
</div>
	</div>
</template> 

<script>

	import {bus} from '@/main'

	import Jiraapi from '@/services/api/jira'
	import moment from 'moment';

	import addtojira from '@/views/tools/addtojira'	
	

	export default{
		name:'linkages',
		props:{
		},

		data(){
			return{
				connectkey:0,
				jiralinks:[]
			}
		},

		mounted(){

			

			let self = this

			Jiraapi.getissues( this.cockpitid, this.token ).then( function( response ){

				console.log( response.data.jira.jiras, "xxxxxxxv")

				if( response.data.jira ){
					self.jiralinks =  response.data?.jira?.jiras
				}
				

			})
		},
		watch:{
		},
		methods:{

			onjirasignin(){

			},

			importjiraissue(){

			},

			timeago( datestr ){

				return moment(datestr).fromNow()


			},

			payloadkey( payloadstr ){

				let payload = JSON.parse(payloadstr)

				return payload["key"]
			},

			payloadurl( payloadstr ){

				let payload = JSON.parse(payloadstr)
				let profile = payload["userprofile"]
				return profile["url"]+"/browse/"+payload["key"]
			}
		},
		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},
			orgkey(){
		    return this.$route.params.orgname ? this.$route.params.orgname : null
		  	},	
			token(){
				return this.$store.getters.token
			},
      		cockpit(){
				return this.$store.getters.GetCockpit
			},
		},

		components:{ addtojira}
	}
	</script>