<template>

	  <span>

	  	<div class="dropdown">

	  		<div>
	  	  			{{formlabel}}
	  	  			<i class="fab fa-trello"></i>
	  	  		</div>

	  	  <div class="card">
	  	  	<div class="card-body p-1" v-if="trelloboards.length == 0 && trellolists.length == 0">


	  	  		<div class="p-2"><a href="#" @click="gettrelloboards">{{ menutitle }}</a></div>

	  	  	</div>

	  	  	<div class="card-body p-2" v-if="trelloboards.length > 0 || trellolists.length > 0">
	  	  		<div class="p-1" v-if="trelloworking"><span ><i class="fa fa-circle-o-notch fa-spin"></i></span> </div>

				    <div class="p-1" :key="board.id"  v-for="board in trelloboards" @click.prevent.stop="getlists(board)">
				    	<a href="#" @click.prevent.stop="getlists(board)">{{ board.name }}</a>
				    </div>
				    <div class="p-1" v-if="menulist=='lists'"><a href="#" @click.prevent.stop="gettrelloboards"><i class="fas fa-arrow-left"></i> Back</a> </div>
				    <div class="p-1" :key="list.id"  v-for="list in trellolists"><a href="#" @click="settrelloboard(trelloboardid, list.id)">{{ list.name }}</a></div>
	  	  	</div>

				   

	  	  </div>


		  
		  <div>
		  	
		    
		  </div>
		</div>

	  </span>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	import Trelloapi from '@/services/api/trello'

	import {bus} from '@/main'


	export default{
		name:'settrelloboard',

		props:{
			formlabel:String,
			connectgroup:String,
		},

		data(){
			return{
						trelloboardname : '',
						trelloauthorized : null,
      			trelloboards : [],
      			trellodefaultboard : {},
      			trellodefaultlist : {},
      			trelloworking : true,
      			trelloboardid : "",
      			trellolistid : "",
      			trellolists : [],
      			menulist : 'boards',
      			board : {},
      			list : {}
				
			}
		},

		watch:{
			
		},
		mounted(){

			bus.$off('createnewcard')
			bus.$on('createnewcard', this.createnewcard)

			let self = this

		      let trelloclient = document.createElement('script')
		      trelloclient.setAttribute('src', 'https://api.trello.com/1/client.js?key=99c5c0224f06e725f1ed901af2376b05')
		      document.head.appendChild(trelloclient) 


		      if( this.cockpit.connect && this.cockpit.connect.trello ){

		      setTimeout( function(){

					self.gettrelloboard( self.cockpit.connect.trello.boardid )
					self.getlist( self.cockpit.connect.trello.listid )

					}, 400)

		    	}

		     // 	}, 500)     	

		      //}

		},
		methods:{

		createnewcard( cardid, name, description, objectid ){


			let self = this

      if( !this.trelloauthorized ){
        this.trelloconnect( function(){
          self.trelloauthorized = true
          self.createnewcard( name, description, objectid )
        })
        return false
      } 

      let proceedtoadd = false

      if( cardid ){

      	Trello.get( "/cards/" + cardid , function(data){ console.log( data, "ok") 


      		if( data ){

      			self.$emit('oncardcreated', data )

      		}else{

      			proceedtoadd = true
      		}


      	}, function(data){ console.log("notok") })

      }else{


      	proceedtoadd = true
      }



			//https://api.trello.com/1/cards?key=${key}&token=${token}&idList=${listId}&desc=test&name=${name}

			//alert('createnewcard')

			if( proceedtoadd ){

				Trello.post("cards?idList="+this.trellolistid+"&desc=" + description + "&name=" + name, function( card ){

				   if( card ){

				   		Trelloapi.bindcard( "cardstoobject", objectid, card.id, self.token ).then( function( response ){

				   			console.log( response )
				   		})
				   }

				}, function(){

					console.log("not ok")
				})

			}




		},

		settrelloboard( trelloboardid, trellolistid ){

			this.trelloworking = true

			let self = this

		//	let board = {}
	//		let list = {}


			Trello.get("lists/"+trellolistid, function( list ){

			   self.trellodefaultlist = list
			   self.trellolistid = trellolistid

			   //console.log( list.name, "ok")


			   Trelloapi.setboard( self.connectgroup, self.cockpitid, list.idBoard, list.id, self.trelloboardname, list.name, self.token ).then( function( response ){

				console.log( response.data )

					if( response.data.ok ){

						//console.log(  "save ok")

						self.trellodefaultlist = response.data.trello					


						self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.trelloboards = []
										self.trellolists = []
									}
								})

					}

				})

			}, function(){

				console.log("not ok")
			})
/*

			this.getlist( trellolistid , function( trellolist ){
				
				self.trellodefaultlist = trellolist	
				list = trellolist//self.getlists( trelloboardid )

						console.log( self.board, self.list , "zzzz")

						self.gettrelloboard( trelloboardid , function( trelloboard ){						

							board = trelloboard

							self.trellodefaultboard = trelloboard
							self.trelloboards = []
							self.trellolists = []
							self.menulist = "boards"

							//self.getlists( trelloboardid )

							self.trelloworking = false


							console.log( self.board, self.list , "zzzzss")
						})
			})

			*/


			/*.then( function(){

					self.gettrelloboard( trelloboardid , function( trelloboard ){

									

									board = trelloboard

									self.trellodefaultboard = trelloboard
									self.trelloboards = []
									self.trellolists = []
									self.menulist = "boards"

									//self.getlists( trelloboardid )

									self.trelloworking = false
								})


					}).then( function(){


						console.log( board, list , "zzzz")
					})

			



			Trelloapi.setboard( this.cockpitid, trelloboardid, trellolistid, this.token ).then( function( response ){

				console.log( response.data )

				if( response.data.ok ){

					
				}

			})*/
		},


		getlist( trellolistid , callback ){

			
			this.trellolistid = trellolistid
			///1/boards/{id}/lists
			let self = this


			Trello.get("lists/"+trellolistid, function( list ){

			   self.trellodefaultlist = list
			   self.trellolistid = trellolistid

			}, function(){

				console.log("not ok")
			}).then( function( data ){

				//self.menulist = 'lists'

				//self.trelloboardid = ""
				self.trelloworking = false
	    
				callback()
	    })


		},

	
		getlists( trelloboard ){

			let trelloboardid = trelloboard.id

			this.trelloboardname = trelloboard.name
			
			this.trelloboardid = trelloboardid
			///1/boards/{id}/lists
			let self = this


			Trello.get("boards/"+trelloboardid+"/lists", function( lists ){

				self.trelloboards = []
				self.trellolists = lists
			   //console.log( lists, "lists")

			}, function(){

				console.log("not ok")
			}).then( function( data ){

				self.menulist = 'lists'

				//self.trelloboardid = ""
				self.trelloworking = false
	        })


		},

		gettrelloboard( trelloboardid , callback ){

      		let self = this

	        if( !this.trelloauthorized ){
	          this.trelloconnect( function(){
	            self.trelloauthorized = true
	            self.gettrelloboard( trelloboardid, {} )
	          })
	          return false
	        }  

			Trello.get("boards/" + trelloboardid, callback, function(){

				console.log("not ok")
			}).then( function( data ){

				console.log(data.name , "zzzzzzzz2z")

	          self.trellodefaultboard = data
	          // console.log( data, "boards")
	          self.trelloboardid = ""
	        })

		},

		gettrelloboards(){

		this.trelloworking = true

		this.menulist = 'boards'

		this.trellolists = []
        this.trelloboards = []

        let self = this

        if( !this.trelloauthorized ){
          this.trelloconnect( function(){
            self.trelloauthorized = true
            self.gettrelloboards()
          })
          return false
        }        

        var getboards = function( data ){
          console.log('boards loaded');
        }


        var getboardsfailed = function() {
          console.log('get boards failed');
        };

        //Trello.boards.get([], getboards, getboardsfailed)

        /*axios.get( "members/me/boards" ).then( function( data ){

           console.log( data, "boards")
        })*/

        Trello.get("members/me/boards", { fields: "id,name"}, getboards, getboardsfailed).then( function( data ){
          self.trelloboards = data
          // console.log( data, "boards")

          self.trelloworking = false
        })


      },

      trelloconnect( callback ){        

        var authenticationSuccess = function( user ) {

          console.log(user, "trello")
          console.log('Successful authentication');
        };

        if( callback ){
          authenticationSuccess = callback
        }

        var authenticationFailure = function() {
          console.log('Failed authentication');
        };

        window.Trello.authorize({
          type: 'popup',
          name: 'Connect with Trello',
          scope: {
            read: 'true',
            write: 'true', account: 'true' },
          expiration: 'never',
          success: authenticationSuccess,
          error: authenticationFailure
        });


      },

			
		},
		computed:{

			menutitle(){


				let title = []

				if( this.cockpit.connect?.trello?.trelloboardname && this.cockpit.connect?.trello?.trellolistname ){
					title.push(this.cockpit.connect.trello.trelloboardname)
					title.push(this.cockpit.connect.trello.trellolistname)
				}
				else{

					if( this.trelloboardname ){
						title.push(this.trelloboardname)
					}

					if( this.trellodefaultlist?.trellolistname ){
						title.push(this.trellodefaultlist.trellolistname )
					}

				}				

				if( title.length == 0 ){
					title.push('Set')
				}				

				return title.join(" - ")
			},

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{},

	}
	//  
</script>