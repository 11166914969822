<template>
	<div>
    <div class ="d-flex " v-if="!haserrors && ( aggregationmethod == 'average' || aggregationmethod == 'optimisticaverage' || aggregationmethod == 'worst' || aggregationmethod == 'best' ) "> 
      <base-icon-label sizeM ="sm" class ="clickable info nav-icon" @click.native.prevent="showinstruction=!showinstruction">
      <template slot = "label-before">
         <span class ="my-auto spr-2" :class = "{'B500-c':showinstruction}">
         Based on: 

         <span :class = "{'B500-c':showinstruction}" v-if="aggregationmethod == 'average'">Average/Low</span>
         <span :class = "{'B500-c':showinstruction}" v-if="aggregationmethod == 'optimisticaverage'">Average/High</span>
         <span :class = "{'B500-c':showinstruction}" v-if="aggregationmethod == 'worst'">Worst</span>
         <span :class = "{'B500-c':showinstruction}" v-if="aggregationmethod == 'best'">Best</span>

         aggregation

       </span> 
      </template>
       <template slot = "icon">
         <span> 
          <i class="fa-info-circle" :class = "{'fal':!showinstruction,'fas':showinstruction}" ></i>
        </span>
      </template>
    </base-icon-label>

</div>

<div :class = "{'newpadding':ispadded, 'newpaddinginner':!ispadded }" class="B100-b" v-if="showinstruction">


  <span v-if="aggregationmethod == 'best'">The status of this objective is determined by taking the highest status of its linked objectives e.g. Red, Red, Red, Green = Green</span>

  <span v-if="aggregationmethod == 'optimisticaverage'">The status of this objective is based on the average status of the linked objectives. If the average results in a tie, then the next higher status is chosen e.g. Red, Red, Green, Green = Green</span>

  <span v-if="aggregationmethod == 'average'">The status of this objective is based on the average status of the linked objectives. If the average results in a tie, then the next lower status is chosen e.g. Red, Red, Green, Green = Red</span>

  <span v-if="aggregationmethod == 'worst'">The status of this objective is determined by taking the lowest status of its linked objectives e.g. Red, Green, Green, Green = Red</span>


</div>


</div>
</template>

<style>

.newpadding{
  margin: 0 -16px 21.5px -16px;
  padding: 4px 16px 4px 16px;
}

.newpaddinginner{
  margin: 0 -8px 0px -8px;
  padding: 4px 8px 0px 8px;
}
</style>

<script>
	export default{
		name: 'basedonaggregation',
		data(){
			return{
				showinstruction:false
			}
		},

		props:{
			haserrors:Boolean,
			aggregationmethod:String,
      ispadded:{
        type: Boolean,
        default: false
      }
    },

    computed:{

    }

  }
</script>