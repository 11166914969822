import { render, staticRenderFns } from "./objectivesTable.vue?vue&type=template&id=2c384be2"
import script from "./objectivesTable.vue?vue&type=script&lang=js"
export * from "./objectivesTable.vue?vue&type=script&lang=js"
import style0 from "./objectivesTable.vue?vue&type=style&index=0&id=2c384be2&prod&lang=scss&spoce=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports