<template>
	<div class="cardwrapper card" style="opacity: .5">
        <div class="row m-2 ">
         <div class="px-2 w-100  d-flex flex-column">
           <h4 class = "objectiveCardtitle d-flex align-items-center" >
             <span class = "overflow-string"><i class="fa fa-circle-o-notch fa-spin"></i></span>
           </h4>
         </div>
       </div>
      </div>
</template>

<script>
	export default{
		name: 'objectivesCardworking',
		data(){
			return{}
		},

		props:{
		},

		computed:{

		}

	}
</script>