<template>

	  <span>


	  	<b-modal v-model="addtojirawithform" id="closingriskpanel" size="md">
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add to jira as an issue</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addtojirawithform = false"></i>
	          </a>
	        </template>	   


	        <div class="mb-3">
					  <label for="input1" class="form-label">Summary</label>
					  <input type="text" class="form-control" id="input1" v-model="jirasummary">
					</div>     

					<div class="mb-3">
					  <label for="input2" class="form-label">Description</label>
					  <textarea class="form-control" v-model="jiradescription"></textarea>
					</div>

					<div class="mb-3">
					  <label for="input3" class="form-label">Project</label>
					  <b-dropdown id="jiraprojects" :text="!selectedjiraproject?'Select':selectedjiraproject.name" class="m-md-2" v-if="selectedjirasite">
					    <b-dropdown-item :key="key" v-for="( project, key) in jiraprojects" @click="setproject(project)" >{{ project.name }} ({{ project.key }})</b-dropdown-item>    
					  </b-dropdown>
					</div>

					<div class="mb-3">
					  <label for="input4" class="form-label">Issue type</label>
					  <b-dropdown id="issuetypes" :text="!selectedjiraissuetype?'Select':selectedjiraissuetype" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( issuetype, key) in issuetypes" @click="setissuetype(issuetype)" >{{ issuetype.name }}</b-dropdown-item>    
					  </b-dropdown>
					</div>


					


					<template v-slot:modal-footer="{ cancel, ok }">
	          <button class="btn btn-primary" @click="savejirawithoptions()">Add to jira</button>
	        </template>

        </b-modal>


	  	<div class="dropdown" v-if="!buttonmode">

	  		<div>
	  	  	Jira
	  	  	<i class="fab fa-jira"></i>
	  	  </div>

	  	  <div class="card">
	  	  <div class="card-body p-1">

	  	  		<div class="p-2" v-if="!hastoken"><a href="#" @click="getjira">Connect</a></div>

	  	  		<div v-else>
	  	  		

	  	  		<b-dropdown id="jiraprofile" :text="!selectedjirasite?'Select':selectedjirasite" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( profile, key) in jiraprofile" @click="getprojects(profile)" >{{ profile.url.replace('https://','') }}</b-dropdown-item>    
					  </b-dropdown>

					  <b-dropdown id="jiraprojects" :text="!selectedjiraproject?'Select':selectedjiraproject.name" class="m-md-2" v-if="selectedjirasite">
					    <b-dropdown-item :key="key" v-for="( project, key) in jiraprojects" @click="setproject(project)" >{{ project.name }} ({{ project.key }})</b-dropdown-item>    
					  </b-dropdown>	

	  	  		</div>

	  	  		

	  	  	</div>
	  	  </div>

		  
		  <div>
		  	
		    
		  </div>
		</div>

	  </span>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	//import Trelloapi from '@/services/api/trello'

	import {bus} from '@/main'
	import axios from 'axios'

	import Jiraapi from '@/services/api/jira'


	export default{
		name:'jiraclassic',

		props:{

			buttonmode:{
          type:Boolean,
          default: false
        }
		},

		data(){
			return{	
				riskid:null,
				issuetypes:null,
				addtojirawithform:false,
				selectedjirasite:"",
				selectedjiraprofile:{},
				selectedjiraproject:null,
				selectedjiraissuetypeid:null,
				selectedjiraissuetype:"",
				jirasummary:"",
				jiradescription:"",
				jiraprofile:{},
				jiraprojects:[],
				authwindow:null,
				

			}
		},

		watch:{
			
		},
		mounted(){

			window.opener1=this;

			bus.$off('createnewissue')
			bus.$on('createnewissue', this.createnewissue)

			bus.$off('createnewissuewithform')
			bus.$on('createnewissuewithform', this.createnewissuewithform)

			this.validatetoken()
		},
		methods:{

			savejirawithoptions(){

				this.confirmcreatenewissue( "", this.jirasummary, this.jiradescription, this.riskid, this.selectedjiraissuetypeid  )
				this.addtojirawithform = false

			},


			setissuetype( issuetype ){

				this.selectedjiraissuetypeid = issuetype.id
				this.selectedjiraissuetype = issuetype.name

			},

			createnewissuewithform( riskid ){

				console.log('xxxxxx')

				this.riskid = riskid
				this.addtojirawithform = true
			},

			openeralert(){

				let self = this

				this.authwindow.close();

				//self.validatetoken( function(){})
				
				self.$emit('onsignin' )
				
			},

			createnewissue( issueid, label, description, riskid ){

				//console.log( "xxxxxxxxxx")
				let self = this

				this.validatetoken( function(){
					//console.log( "zzzzzzz")
					self.confirmcreatenewissue( issueid, label, description, riskid )
				})

			},

			confirmcreatenewissue( issueid, label, description, riskid, issuetype ){

				
				if( issuetype == null ){
					issuetype = 3
				}
				//localStorage.jiraprofile =  ""
   		 	//localStorage.jiratoken = ""

   		 	let self = this
				
				self.jiraprofile = JSON.parse(localStorage.jiraprofile)
				

				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				//console.log( self.jiraprofile )

				//let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				let adf = {"version": 1,
									  "type": "doc",
									  "content": [
									    {
									      "type": "paragraph",
									      "content": [
									        {
									          "type": "text",
									          "text": description
									        }
									      ]
									    }
									  ]
									}

				let params = { "fields": {
									       "project":
									       {
									          "key": "TEST"//this.selectedjiraproject.key
									       },
											       "summary": label,
											       "description": adf,
											       "issuetype": {
											          "id": issuetype
											       }
   												}}

   		  let url = "https://api.atlassian.com/ex/jira/" + self.jiraprofile[0].id + "/rest/api/3/issue"
   												
   			//let url = "https://api.atlassian.com/ex/jira/" + "9b041472-5ebe-4e35-8249-218a47c32688" + "/rest/api/2/issue/createmeta"

				axios.post(  url, params, headers ).then( function( response ){
					//console.log( response.data , "createnu")

					if( response.data ){

						if(  self.jiraprofile[0] && response.data ){
							response.data.userprofile = self.jiraprofile[0]
							response.data.issueurl = self.jiraprofile[0]?.url + "/browse/" + response.data.key
						}						

						Jiraapi.issuecreated(  "bindrisktoissue", self.cockpitid, riskid, response.data.id, JSON.stringify(response.data),  self.token )

						self.$emit('onissuecreated' )
					}

					
				})


			},

			setproject( project , donotsave ){

				this.selectedjiraproject = project

				if( donotsave && donotsave == true ) return

				Jiraapi.setproject( "selectedjiraproject", this.cockpitid,  project.id, this.selectedjiraprofile.id, project.name, this.selectedjiraprofile.url.replace('https://',''), this.token ).then( function( response ){

				   			console.log( response )
				   		})
			},

			getprojects( profile ){

				let self = this
				this.jiraprojects = []

				this.selectedjirasite = profile.url.replace('https://','')

				this.selectedjiraprofile = profile

				let jiratoken = localStorage.jiratoken

				//console.log( jiratoken , "ttttttt")

				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				let url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/project/search"

				axios.get( url, headers  ).then( function( response ){

					//console.log( response.data , "zxxasd")

					if( response.data?.values ){
						self.jiraprojects = response.data.values

						self.jiraprojects.forEach(function (element) {

							if( self.cockpit.connect?.jira.jiraprojectid == element.id ){
								//console.log( element.id , "asdasd")
								self.selectedjiraproject = element
							}

						})

						
					}
					
				})

				url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/issuetype"

				axios.get( url, headers  ).then( function( response ){

					self.issuetypes = response.data


					
				})


				//console.log( profile.id )
			},


			popup(url, title, width, height) {

        var left = (screen.width / 2) - (width / 2);
        var top = (screen.height / 2) - (height / 2);
        var options = '';    
        options += ',width=' + width;
        options += ',height=' + height;
        options += ',top=' + top;
        options += ',left=' + left;    
        return window.open(url, title, options);

    	},

			validatetoken( callback ){

				console.log( "validating")

				let self = this


				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				if( !localStorage.jiratoken ){

					console.log( "no token")
					return false
				}

				axios.get( "https://api.atlassian.com/oauth/token/accessible-resources", headers  ).then( function( response ){

					console.log( "no token")

       				//console.log( response.data, "jiratoken")
       				
       				//let requestingurl = localStorage.requestingurl

       				//if( !requestingurl ){
       				//	requestingurl = "/"
       				//}
					    localStorage.jiraprofile =  JSON.stringify(response.data)
       				self.jiraprofile = JSON.parse(localStorage.jiraprofile)
       				//self.jiraprofile 


   		 		}).then( function(){

   		 				self.jiraprofile.forEach(function (element) {

   		 					if( self.cockpit.connect?.jira.jiraprofileid == element.id ){
   		 						self.selectedjiraprofile = element
   		 						self.selectedjirasite = element.url.replace('https://','')
   		 						//self.selectedjiraproject 

   		 						self.getprojects( element )


   		 					}

   		 				})

   		 				if( callback ){
   		 					callback()
   		 				}

   		 				

   		 		}).catch( function(  error ){

   		 			if( error.response.status == 401 ){
   		 				localStorage.jiratoken = ""
   		 			}

   		 			console.log( error.response.status,  "er")
   		 			//localStorage.jiratoken = ""
   		 			//self.getjira()
   		 		})


			},

			getjira(){


				

				//window.open( this.jiralocal())

				localStorage.requestingurl = window.location.href
				this.authwindow = this.popup( "/apitoken?loading="+ encodeURIComponent(this.jiraurl)+"&redirto="+this.jiraredirecturl, 'Open Jira', 600, 400)
				///location.href = this.jiraurl

			},

		},
		computed:{


			hastoken(){

				let jiratoken = localStorage.jiratoken

				if( !localStorage.jiratoken ){
					return false
				}else{
					return true
				}

			},

			jiraredirecturl(){


				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				let local = "http%3A%2F%2Flocalhost%3A8080%2Fapitoken&state=Qddib53BT3khZ22krZTxBnW2"

				let test = "https%3A%2F%2Fdev2.flowe.io%2Fapitoken&state=vVFvdqV1K5b9uN6YNJMpdRlX"

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn

			},

			jiraurl(){

				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				let local = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&response_type=code&prompt=consent&redirect_uri="

				let test = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=OrTRI9oWH962QAmvpccB3Y31aCvv8gFk&scope=read%3Ajira-work%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&response_type=code&prompt=consent&redirect_uri="

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn
			},

			menutitle(){ 

				let title = ""

			//	tracks.forEach(function (element) {})

				title = this.jiraprofile ? this.jiraprofile.url : 'Set' 

				return title
			},

			localjiratoken(){

				return localStorage.jiratoken
			},

			cockpitid(){
        return this.$route.params.cid
     	},
			orgkey(){
		    return this.$route.params.orgname ? this.$route.params.orgname : null
		  },	
			token(){
				return this.$store.getters.token
			},
      cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{},

	}
	//  
</script>