<template>

	  <span>
	  	
	  	<div v-if="issueurl!=''">{{ issueurl }}</div>
	  	<button v-if="renderButton=='addto'" class="btn btn-secondary" @click.stop.prevent="createnewissuewithform">Add to Jira</button>
	  	<button v-if="renderButton=='import'" class="btn btn-secondary" @click.stop.prevent="onimportjiraissue">Add issue</button>


	  	<b-modal v-model="importjiraissue" id="importjiraissuepanel" size="md" @shown="importjiraissueshown">
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Import Jira issue</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="importjiraissue = false"></i>
	          </a>
	        </template>	

	        <div v-if="!hastoken">	        	
	        	You need to <a href="#" @click="getjira">connect to Jira</a>
	        </div> 

	        <div v-else>
	        	
	        	<div class="mb-1">
					  <label for="input1" class="form-label">Site</label>
					  <b-dropdown id="jirasite" :text="selectedjirasite ? selectedjirasite.url.replace('https://','') : 'Select site'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( profile, key) in jiraprofile" @click="setsite(profile)">{{ profile.url.replace('https://','') }}</b-dropdown-item>    
					  </b-dropdown>
					</div>

	        		<div class="mb-1">
					  <label for="input3" class="form-label">Project</label>
					  <b-dropdown v-if="selectedjirasite"  id="jiraprojects" :text="selectedjiraproject ? selectedjiraproject.name : 'Select project'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( project, key) in jiraprojects" @click="setproject(project)" >{{ project.name }} ({{ project.key }})</b-dropdown-item>    
					  </b-dropdown>
					</div>


					<div class="mb-1">
					  <label for="input3" class="form-label">Issues</label>
					  <b-dropdown v-if="selectedjirasite"  id="jiraissues" :text="'Select issue'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( jiraissue, key) in jiraissues" @click="setproject(project)" >{{ jiraissue }})</b-dropdown-item>    
					  </b-dropdown>
					</div>

	        </div>

	    </b-modal>
	  	<b-modal v-model="addtojirawithform" id="closingriskpanel" size="md" :key="addtojirawithformmodal" @shown="addtojirawithformshown">
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add to Jira</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addtojirawithform = false"></i>
	          </a>
	        </template>	

	        <div v-if="!hastoken">	        	
	        	You need to <a href="#" @click="getjira">connect to Jira</a>
	        </div>   

	        <div v-else>

	        	<div class="text-danger" v-if="jiraerrors">{{ jiraerrors }}</div>

	        		<div class="mb-1">
					  <label for="input1" class="form-label">Site</label>
					  <b-dropdown id="jirasite" :text="selectedjirasite ? selectedjirasite.url.replace('https://','') : 'Select site'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( profile, key) in jiraprofile" @click="setsite(profile)">{{ profile.url.replace('https://','') }}</b-dropdown-item>    
					  </b-dropdown>
					</div>

	        		<div class="mb-1">
					  <label for="input3" class="form-label">Project</label>
					  <b-dropdown v-if="selectedjirasite"  id="jiraprojects" :text="selectedjiraproject ? selectedjiraproject.name : 'Select project'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( project, key) in jiraprojects" @click="setproject(project)" >{{ project.name }} ({{ project.key }})</b-dropdown-item>    
					  </b-dropdown>
					</div>

					<div class="mb-1">
					  <label for="input4" class="form-label">Issue type</label>
					  <b-dropdown v-if="selectedjirasite" id="issuetypes" :text="selectedjiraissuetype ? selectedjiraissuetype.name : 'Select issue type'" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( issuetype, key) in issuetypes" @click="setissuetype(issuetype)" >{{ issuetype.name }}</b-dropdown-item>    
					  </b-dropdown>
					</div>

					<div v-if="selectedjiraissuetype">

					<div class="text-danger" v-if="jiraerrors && ( !jiraerrors.summary || !jiraerrors.description || !jiraerrors.assignee || !jiraerrors.duedate || !jiraerrors.epicname  ) "></div>

					<div class="mb-3" v-if="selectedjiraissuetype.id==10000">
					  <label for="input0" class="form-label">Epic name</label>
					  <input type="text" class="form-control" id="input5" v-model="jiraepicname">
					  <div class="text-danger" v-if="jiraerrors.epicname">{{jiraerrors.epicname}}</div>
					</div>

					<div class="mb-3" v-if="selectedjiraissuetype.fields.summary">
					  <label for="input1" class="form-label">{{ selectedjiraissuetype.fields.summary.name }}</label>
					  <input type="text" class="form-control" id="input1" v-model="jirasummary">
					  <div class="text-danger" v-if="jiraerrors.summary">{{jiraerrors.summary}}</div>
					</div>

					<div class="mb-3" v-if="selectedjiraissuetype.fields.description">
					  <label for="input2" class="form-label">{{ selectedjiraissuetype.fields.description.name }}</label>
					  <textarea class="form-control"></textarea>
					  <div class="text-danger" v-if="jiraerrors.description">{{jiraerrors.description}}</div>
					</div>					

					<div class="mb-3" v-if="selectedjiraissuetype.fields.assignee">
					  <label for="input4" class="form-label">{{ selectedjiraissuetype.fields.assignee.name }}</label>
					  <b-dropdown id="assignableusers" :text="!selectedjirauser?'Select':selectedjirauser.displayName" class="m-md-2">
					    <b-dropdown-item :key="key" v-for="( jirauser, key) in jirausers" @click="setassignment(jirauser)" >{{ jirauser.displayName }}</b-dropdown-item>    
					  </b-dropdown>
					  <div class="text-danger" v-if="jiraerrors.assignee">{{ jirauser.displayName }}</div>
					  <vueselect v-if="1==2" @search="assigneefetch" :options="jirausers">
					  	<template #option="{ emailAddress, displayName }">
					      <div>{{displayName}}</div>
					    </template>
					  </vueselect>
					</div>

					<div class="mb-3" v-if="selectedjiraissuetype.fields.duedate">
					  <label for="input5" class="form-label">{{ selectedjiraissuetype.fields.duedate.name }}</label>
					  <b-form-datepicker id="input5" v-model="selectedjiraduedate" class="mb-2"></b-form-datepicker>
					  <div class="text-danger" v-if="jiraerrors.duedate">{{jiraerrors.duedate}}</div>
					</div>

					<div v-if="1==2">
						<template v-for="( field, key) in selectedjiraissuetype.fields" >
							<div class="mb-1" :key="key">
							  <label for="input5" class="form-label">Name:<b>{{ field.name }}</b> Required:{{ field.required }} Type:{{ field.schema.type }}</label>
							</div>
						</template></div>

					</div>

			</div>


					


					<template v-slot:modal-footer="{ cancel, ok }"> 
					<div class="" style=""><a href="#" @click.stop.prevent="signoutjira()" v-if="hastoken">Sign out Jira</a></div>
	         		<button class="btn btn-primary" @click="savejirawithoptions()" v-if="hastoken">Add to jira</button>
	         		<button class="btn btn-primary" disabled v-if="!hastoken">Add to jira</button>
	        		</template>

        </b-modal>


	  </span>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	//import Trelloapi from '@/services/api/trello'

	import {bus} from '@/main'
	import axios from 'axios'
	import vueselect from '@/views/tools/vueselect'

	import Jiraapi from '@/services/api/jira'


	export default{
		name:'jiraclassic',

		props:{

			renderButton:{
          	type:String,
          	default: ""
        	},

			buttonmode:{
          	type:Boolean,
          	default: false
        	},

        	issueurl:{
          	type:String,
          	default: ""
        	},

        	objectid:{
          	type:String,
          	default: ""
        	}
		},

		data(){
			return{
				jiraerrors:{},
				//selproject:{},
				jiraissues:null,
				addtojirawithformmodal:0,
				importjiraissuemodal:0,
				riskid:null,
				issuetypes:null,
				jirausers:null,
				jirafields:null,
				importjiraissue:false,
				addtojirawithform:false,
				selectedjirasite:"",
				selectedjiraprofile:null,
				selectedjiraproject:null,
				selectedjiraissuetypeid:null,
				selectedjiraissuetype:null,
				selectedjirauser:null,
				selectedjirauserid:null,
				selectedjiraduedate:null,
				jirasummary:"",
				jiradescription:"",
				jiraepicname:"",
				jirassignee:"",				
				jiraprofile:{},
				jiraprojects:[],
				jirausers:[],
				authwindow:null,
				jiratoken:null,
			}
		},

		watch:{
			
		},
		mounted(){			

			window.opener1=this;

			//bus.$off('onimportjiraissue')
			//bus.$on('onimportjiraissue', this.importjiraissue)

			bus.$off('createnewissue')
			bus.$on('createnewissue', this.createnewissue)

			bus.$off('createnewissuewithform')
			bus.$on('createnewissuewithform', this.createnewissuewithform)

			this.validatetoken()
		},
		methods:{

			getissues( project ){

				let self = this

				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				let profile = JSON.parse(localStorage.jiraprofile)


				//let url = this.selectedjiraissuetype.fields?.assignee?.autoCompleteUrl + search	
				let url = "https://api.atlassian.com/ex/jira/" + selectedjiraproject.id + "/rest/api/3/search?jql="+ project.key


				axios.get( url, headers  ).then( function( response ){
					console.log( response.data , "api")
					self.jiraissues = response.data
				})

				//https://your-domain.atlassian.net/rest/api/3/search?jql=project%20%3D%20HSP
			},

			onimportjiraissue(){

				this.importjiraissue = true

			},

			importjiraissueshown(){


			},

			setsite( site ){

				this.selectedjirasite = site

				this.selectedjiraproject = null
				this.selectedjiraissuetype = null

				this.jiraprojects = null
				this.issuetypes = null

				this.jiraprofile = JSON.parse(localStorage.jiraprofile)
       			this.selectedjiraprofile = site


				this.getprojects(site)

			},

			assigneefetch( search, loading ){

				let self = this

				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				let profile = JSON.parse(localStorage.jiraprofile)

				let url = this.selectedjiraissuetype.fields?.assignee?.autoCompleteUrl + search	

				axios.get( url, headers  ).then( function( response ){
					console.log( response.data , "users")
					self.jirausers = response.data
				})


				console.log( search, loading )

			},

			addtojirawithformshown(){

				this.validatetoken()
			},

			signoutjira(){

				this.jiratoken = ""
				localStorage.jiratoken = ""
				this.$emit('onsignin' )

			},

			savejirawithoptions(){

				this.confirmcreatenewissue( "", this.jirasummary, this.jiradescription, this.objectid, this.selectedjiraissuetype.id  )
				//this.addtojirawithform = false

			},

			setassignment( user ){

				this.selectedjirauser = user

			},

			setissuetype( issuetype ){

				console.log( issuetype.fields , "fields")

				/*


				let self = this

				//this.selectedjiraproject = project
				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }
				let profile = JSON.parse(localStorage.jiraprofile)

				let url = ""

			//	let url = "https://api.atlassian.com/ex/jira/" + profile[0].id + "/rest/api/3/field"

				//let url = "https://api.atlassian.com/ex/jira/" + profile[0].id + "/rest/api/3/issuetype/" + issuetype.id
				//"/rest/api/3/issuetypescreenscheme/project?projectId={projectId}"
				//"/rest/api/3/issue/" + issuetype.id


				axios.get( url, headers  ).then( function( response ){
					console.log( response.data , "form")*/

					/*self.selectedjiraproject.issuetypes = []


					if( response.data ){
						self.issuetypes = response.data

						if( self.issuetypes[0] ){
							self.setissuetype( self.issuetypes[0] )
						} 
					}*/
				
				/*})*/


				this.selectedjiraissuetype = issuetype

				this.assigneefetch("")

			},

			createnewissuewithform( objectid ){

/*
				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }


				let profile = JSON.parse(localStorage.jiraprofile)

				let url = "https://api.atlassian.com/ex/jira/" + profile[0].id + "/rest/api/3/issue/createmeta?expand=projects.issuetypes.fields&projectKeys=IN"

				

				axios.get( url, headers  ).then( function( response ){
					console.log( response.data , "form")
				})
*/


				//console.log('xxxxxx')

				//this.riskid = objectid
				this.addtojirawithform = true
			},

			openeralert( jiratoken ){

				let self = this

				this.jiratoken = jiratoken

				this.authwindow.close();

				setTimeout( function(){
					self.validatetoken()
				}, 200)

				//self.validatetoken( function(){})
				//this.addtojirawithformmodal += 1				
				//self.$emit('onsignin' )
				
			},

			createnewissue( issueid, label, description, objectid ){

				//console.log( "xxxxxxxxxx")
				let self = this

				this.validatetoken( function(){
					//console.log( "zzzzzzz")
					self.confirmcreatenewissue( issueid, label, description, objectid )
				})

			},

			confirmcreatenewissue( issueid, label, description, objectid, issuetype ){

				
				if( issuetype == null ){
					issuetype = 3
				}
				//localStorage.jiraprofile =  ""
   		 	//localStorage.jiratoken = ""

   		 	let self = this
				
				self.jiraprofile = JSON.parse(localStorage.jiraprofile)
				
				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				//console.log( self.jiraprofile )

				//let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				let adf = {"version": 1,
									  "type": "doc",
									  "content": [
									    {
									      "type": "paragraph",
									      "content": [
									        {
									          "type": "text",
									          "text": description
									        }
									      ]
									    }
									  ]
									}


									//alert( this.selectedjirauser.id )

				console.log( this.selectedjirauser )

				//return false


				let params = { "fields": {
						       "project":
						       {
						          "key": this.selectedjiraproject.key
						       },
						       "summary": label,
						       "description": adf,
						       "issuetype": {
						          "id": issuetype
						       },
						       "duedate": this.selectedjiraduedate,
						       "assignee": {
						          "accountId": this.selectedjirauser ? this.selectedjirauser.accountId : null
						       }
							}}

   		  let url = "https://api.atlassian.com/ex/jira/" + self.selectedjiraprofile.id + "/rest/api/3/issue"
   												
   			//let url = "https://api.atlassian.com/ex/jira/" + "9b041472-5ebe-4e35-8249-218a47c32688" + "/rest/api/2/issue/createmeta"

				axios.post(  url, params, headers ).then( function( response ){
					//console.log( response.data , "createnu")

					if( response.data ){

						if(  self.selectedjiraprofile && response.data ){
							response.data.userprofile = self.selectedjiraprofile
							response.data.issueurl = self.selectedjiraprofile?.url + "/browse/" + response.data.key
						}						

						Jiraapi.issuecreated(  "bindrisktoissue", self.cockpitid, objectid, response.data.id, JSON.stringify(response.data),  self.token )

						self.$emit('onissuecreated' )

						self.addtojirawithform = false
					}

					
				}).then( function( response ){

					console.log( response, "erresponse" ) 

				}).catch( function( error, message ){

					self.jiraerrors = error.response?.data?.errors

					

				})


			},

			setproject( project , donotsave ){

				let self = this

				this.selectedjiraproject = project


				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }
				//let profile = JSON.parse(localStorage.jiraprofile)


				//let url = "https://api.atlassian.com/ex/jira/" + profile[0].id + "/rest/api/3/issuetype/project?projectId=" + project.id
				//"/rest/api/3/issuetype"
				let url = "https://api.atlassian.com/ex/jira/" + this.selectedjiraprofile.id + "/rest/api/3/issue/createmeta?expand=projects.issuetypes.fields&projectId=" + project.id		

				//&issuetypeIds=Bug

				//let url = "https://api.atlassian.com/ex/jira/" + profile[0].id + "/rest/api/3/issue/createmeta/"+project.id+"/issuetypes"
				//this.issuetypes = []
				axios.get( url, headers  ).then( function( response ){
					console.log( response.data , "form")

					self.selectedjiraproject.issuetypes = []


					/*if( response.data ){
						self.issuetypes = response.data

						if( self.issuetypes[0] ){
							self.setissuetype( self.issuetypes[0] )
						} 
					}*/

					if( response.data?.projects[0]?.issuetypes ){
						//self.selectedjiraproject.issuetypes = 
						self.issuetypes = response.data?.projects[0]?.issuetypes

						//if( self.issuetypes[0] ){
						//	self.setissuetype( self.issuetypes[0] )
						//} 
					}
					
				})

				/*if( donotsave && donotsave == true ) return

				Jiraapi.setproject( "selectedjiraproject", this.cockpitid,  project.id, this.selectedjiraprofile.id, project.name, this.selectedjiraprofile.url.replace('https://',''), this.token ).then( function( response ){

				   			console.log( response )
				   		})*/
			},

			getprojects( profile ){

				let self = this
				this.jiraprojects = []

				//this.selectedjirasite = profile.url.replace('https://','')

				this.selectedjiraprofile = profile

				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken

				let projectid = ""

				//console.log( jiratoken , "ttttttt")

				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				//let url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/project/recent"
				let url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/project/search"

				axios.get( url, headers  ).then( function( response ){

					//console.log( response.data , "zxxasd")

					if( response.data?.values ){
						self.jiraprojects = response.data.values

						if( self.jiraprojects[0] ){
							//self.selproject = self.jiraprojects[0]

							//self.setproject( self.jiraprojects[0] )
						}
						

						/*self.jiraprojects.forEach(function (element) {

							if( self.cockpit.connect?.jira.jiraprojectid == element.id ){
								//console.log( element.id , "asdasd")
								self.selectedjiraproject = element
								projectid = element.id
							

								url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/issuetype"

								axios.get( url, headers  ).then( function( response ){
									self.issuetypes = response.data					
								})

								//url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/2/users"

								url = "https://api.atlassian.com/ex/jira/" + profile.id + "/rest/api/3/user/assignable/search?project=" + projectid


								axios.get( url, headers  ).then( function( response ){

									self.jirausers = response.data
									
								})



							}

						})*/

						
					}
					
				})

				

				//https://your-domain.atlassian.net/rest/api/2/users


				//console.log( profile.id )
			},


			popup(url, title, width, height) {

        var left = (screen.width / 2) - (width / 2);
        var top = (screen.height / 2) - (height / 2);
        var options = '';    
        options += ',width=' + width;
        options += ',height=' + height;
        options += ',top=' + top;
        options += ',left=' + left;    
        return window.open(url, title, options);

    	},

			validatetoken( callback ){

				console.log( "validating")

				let self = this

				this.jiratoken = localStorage.jiratoken
				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				if( !localStorage.jiratoken ){

					console.log( "no token")
					return false
				}

				axios.get( "https://api.atlassian.com/oauth/token/accessible-resources", headers  ).then( function( response ){

					console.log( "no token")

       				//console.log( response.data, "jiratoken")
       				
       				//let requestingurl = localStorage.requestingurl

       				//if( !requestingurl ){
       				//	requestingurl = "/"
       				//}
					localStorage.jiraprofile =  JSON.stringify(response.data)
       				self.jiraprofile = JSON.parse(localStorage.jiraprofile)
       				//self.selectedjiraprofile = self.jiraprofile[0]
       				//self.jiraprofile 


   		 		}).then( function(){

   		 				//self.jiraprofile.forEach(function (element) {

   		 					//if( self.cockpit.connect?.jira.jiraprofileid == element.id ){
   		 						
   		 						//self.selectedjiraprofile = element
   		 						//self.selectedjirasite = element.url.replace('https://','')
   		 						//self.selectedjiraproject 

   		 						self.getprojects( element )


   		 					//}



   		 				//})

   		 				self.getprojects( self.selectedjiraprofile )

   		 				if( callback ){
   		 					callback()
   		 				}

   		 				

   		 		}).catch( function(  error ){

   		 			console.log( error.response?.message , "err")

   		 			if( error.response?.status == 401 ){

   		 				self.jiratoken = ""
   		 				//self.jiratoken = localStorage.jiratoken
   		 				localStorage.jiratoken = ""

   		 			}

   		 			//console.log( error..status,  "er")
   		 			//localStorage.jiratoken = ""
   		 			//self.getjira()
   		 		})


			},

			getjira(){


				

				//window.open( this.jiralocal())

				localStorage.requestingurl = window.location.href
				this.authwindow = this.popup( "/apitoken?loading="+ encodeURIComponent(this.jiraurl)+"&redirto="+this.jiraredirecturl, 'Open Jira', 600, 400)
				///location.href = this.jiraurl

			},

		},
		computed:{


			hastoken(){

				//let jiratoken = localStorage.jiratoken

				if( !this.jiratoken ){
					return false
				}else{
					return true
				}

			},

			jiraredirecturl(){


				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				//let local = "http%3A%2F%2Flocalhost%3A8080%2Fapitoken&state=Qddib53BT3khZ22krZTxBnW2"

				let local = "http%3A%2F%2Flocalhost%3A8080%2Fapitoken"

				let test = "https%3A%2F%2Fdev2.flowe.io%2Fapitoken"

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn

			},

			jiraurl(){

				let baseurl = window.location.href

				let isdev2 = baseurl.includes("dev2");

				let toreturn = ""

				/*https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fapitoken&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent*/

				/*let local = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration&state=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&response_type=code&prompt=consent&redirect_uri="*/

				//let local = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work%20manage%20manage%3Ajira-configuration%3Ajira-project&response_type=code&prompt=consent&state=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&redirect_uri="

				let local = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&response_type=code&prompt=consent&redirect_uri="

				//let test = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=OrTRI9oWH962QAmvpccB3Y31aCvv8gFk&scope=read%3Ajira-work%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&response_type=code&prompt=consent&state=vVFvdqV1K5b9uN6YNJMpdRlX&redirect_uri="

				let test = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=OrTRI9oWH962QAmvpccB3Y31aCvv8gFk&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&response_type=code&prompt=consent&state=vVFvdqV1K5b9uN6YNJMpdRlX&redirect_uri="

				toreturn = local

				if( isdev2 ){
					toreturn = test
				}

				return toreturn
			},

			menutitle(){ 

				let title = ""

			//	tracks.forEach(function (element) {})

				title = this.jiraprofile ? this.jiraprofile.url : 'Set' 

				return title
			},

			localjiratoken(){

				return localStorage.jiratoken
			},

			cockpitid(){
        		return this.$route.params.cid
     		},
			orgkey(){
		    return this.$route.params.orgname ? this.$route.params.orgname : null
		  	},	
			token(){
				return this.$store.getters.token
			},
      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{vueselect},

	}
	//  
</script>