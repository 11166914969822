<template>
  
  <ul class="nav nav-tabs indicator_tab" role="tablist" >
     <li  v-if="objective.indicators && objective.indicators.length > 0" class=" hasleft indicatorsmove" :id= "'indicatorsmove_'+objective._id" role="presentation" style ="list-style: none;">
        <base-icon class=" moveleft icon-holder" :class = "{'disabled':!hasleft} " href="#" :disabled="!hasleft" @click.prevent="tabmove($event,objective,'left')" icon ="fas fa-caret-left"/>
   </li>
   <li class="nav-item indicatortab "  v-if="!objective.indicators">
    <base-icon icon ="fa fa-circle-o-notch fa-spin"/>
  </li>

  <!-- tabs loops starts -->
  <!-- data-toggle="tooltip" data-placement="top" title="09 Sep 2018" --> 
  <li v-else-if="objective.indicators && objective.indicators.length > 0" class="nav-item indicatortab showdatetooltip" :id="'indicatortab_'+objective._id+'_'+index" v-for="(indicator, index) in objective.indicators" v-bind:key="indicator.id">

     <base-icon
     class="nav-link" 
     data-toggle="tab"
     :tabid="'#' +scope+ '_indicatordetails_'+objective._id+'_'+indicator._id" 
     role="tab" 

     >
      <template v-slot:icon>
         <span v-html="$options.filters.statusoptionicon(indicator)"></span>
      </template>
     
   </base-icon>

  </li>
  <!-- tabs loops ends -->
  <li class=" hasright indicatorsmove" role="presentation" style ="margin-bottom: -1px;" v-if="objective.indicators && objective.indicators.length > 0">
    <base-icon class=" moveright icon-holder" :class = "{'disabled':!hasright} " href="#" :disabled="!hasright" @click.prevent="tabmove($event,objective,'right')" icon ="fas fa-caret-right"/>
   </li>

  </ul>
</template>
<style scoped>
  .nav-link{
    padding:0;
  }
</style>
<script type="text/javascript"></script>

<script>
  import statusindicatorer from './statusindicatorer'

  export default{
    name:'statusindicatorerow',
    data(){
     return{
      activeindicator:null,
      cursor:{position: 0, hasleft:false, hasright:false},
      perpage: 10,
      delayshow:1000,

    }
  },

  computed:{

    token(){
    return localStorage.token
  },


   screenType(){
     return this.$store.state.screenType
   },
   hasleft(){
     return this.cursor.hasleft
   },
   hasright(){
     return this.cursor.hasright
   },

   isActive(){
     if(this.$route.query.oid == this.objective._id){
       return true
     } else {
       return false
     }
   },
   tooltipdelay(){
     return '{\"show\":\"'+this.delayshow+'\",\"hide\":\"0\"}'
   },
   objectiveid(){
    return this.objective._id
  }
},
props:{
 objective:{},
 scope:{
  type:String,
  default:'index'
}
},
mounted(){
 let self = this
 setTimeout(function(){
  self.ribbonbar()
  self.addtooltoolTips()
},300)
 window.addEventListener('resize',this.ribbonbar)
},

methods:{
 addtooltoolTips(){
 //  let self = this
 //  if(this.$store.state.hasTouch){
 //   var card = $('#'+self.scope+ '_' + self.objective._id)
 //   var tooltip = $('[data-toggle="tooltip"]',card)
 //   $(function () {
 //     tooltip.tooltip({delay: {show:500, hide:0}})
 //   })

 //   tooltip.on('mouseenter', function(e){
     
 //     tooltip.each(function(){
 //       var newself = this
 //       setTimeout(function(){
 //         $(newself).data('bs.tooltip').config.delay = {show:0, hide:0}
 //       },300)                   
 //     })
     
 //   }).on('mouseleave',function(e){
 //     tooltip.each(function(){
 //       var newself = this
 //       setTimeout(function(){
 //         $(newself).data('bs.tooltip').config.delay = {show:500, hide:0}
 //       })
 //     })
 //   })
 // }
},

  tabmove : function( ev, objective, moveto, movetoablselut ){
  },

      ribbonbar : function(){ //counts tab, if more than 10 hide other links 
        if( self.objective.indicators && this.objective.indicators.length){
          var card = $('#'+this.scope + '_' + this.objective._id);
          var arrow = 100
          var w = card.innerWidth() - arrow;
          var icons = 32

          var perpage 
          if(w <= 0){
            perpage = 6
          } else {perpage = this.perpage < Math.floor(w/icons)  ? this.perpage: Math.floor(w/icons);}
          var items = $('#' + this.scope + '_' +this.objective._id).find(".indicator_tab" );
          this.perpage = perpage
          var position = this.cursor.position;

          if(this.objective.indicators.length > perpage ){

           this.cursor.position = this.objective.indicators.length -  perpage;
           this.cursor.hasleft = true;


         } else if ( this.objective.indicators.length <= perpage){
          this.cursor.hasleft = false;
          this.cursor.hasright = false;
          this.cursor.position = 0;


        }


        items.each( function( i, o ){                

         var tabs = $('.indicatortab', o )

         if( tabs.length + 1 > perpage){
                     //  console.log('tabs: ',tabs ) 
                     position = tabs.length - perpage;
                     
                     tabs.addClass("hide")
                     tabs.slice( position, tabs.length ).removeClass("hide")

                   }                   

                 })  

      }

      $('.indicator_development' , card ).removeClass('hide')


    },


    indicatorlinkclick : function( objective, indicator){

      let self = this

      this.activeobjective = objective
      this.loadComments(indicator)
      this.loadLikes( indicator )

      self.loadingindicatordata = {like:true, comments:true}

      Cockpitsapi.Tabclicked( indicator._id, self.token)

      

      setTimeout( function(){
                  //console.log( $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext') )

                  $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext').off('click').on('click', function( i,o ){
                    self.attachviewer( i.currentTarget )
                  }).addClass('viewer')

                  autosize($('.autogrow'));

                }, 100)

      self.$func.addQuery({oid:objective._id,iid:indicator._id, modal:'no'}, self.$route, self.$router)


    },
  }
}
</script>