<template>
         		
        <div  tabindex="0">
        	
<!--@focus="handleFocus" @focusout.stop.prevent="handleFocusOut"-->
        	<memberslist 		
        		@onuserinteraction="userinteraction"
						:users="contact.contacts" 
						:listtype="'stakeholderscontacts'"
						:adminrole="adminrole"
						:orgkey="orgkey"
						:username="username"
						:team="team"
						:canwrite="canwrite"
						:boardusers="boardusers"/>

					 <div id="search-objusers" v-if="mode=='idle' && canwrite">
						<input  @click="newcontact" type="text"   style="width:260px;" class="vs__search clicktoadd"  placeholder="Click to add more contacts"></div>

					 	<membersearch 
					 		:addbutton="true"
					 		v-if="mode=='addnew' && canwrite"
					 		:mode="mode"
					 		:placeholder2="'Click to add more contacts'"
					 		@addascontact="addthiscontact"
					 		@handlefocusout="handlefocusout"
					 		@onsearchonkeyup="onsearchonkeyup"
						 	@onupdated="onupdated" 
						 	@availableusersupdate="availableusersupdate" 
						 	@userSelect ="userSelect" 
						 	@oninviteuser ="inviteuser" 
						 	@onsearchactive="searchactive"
						 	:users = "users" 
						 	:availableusers="availableusers" 
						 	:alreadyonlist ="alreadyonlist"
						 	:sendaninvite="sendaninvite"
						 	:isactive ="isactive" 
						 	:allowadduser="allowadduser" 
						 	:adminrole="adminrole"  
						 	:myrole="myrole" 
						 	:isadmanaged="isadmanaged"
						 	:username="username" 
						 	:allowsendaninvite="false"
						 	:searchquery="searchquery"
						 	/>

              <input style="width:270px;" type="text" class=" hide form-control form-control-sm" placeholder="Add a new contact" v-model="searchtext"> <button class="btn btn-primary hide" type="submit">Add</button>           


        </div>
</template>
<style scoped>

	.btn:focus, .btn.focus {
	    outline: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}

	
	.vs__selected{
	  display: none !important;
	}

/*
#search-objusers, #search-objusers-objectives {
    border: 1px solid #d5dae3;
    border-radius: 0px;
    background-color: #d5dae3;
}
	#search-objusers, #search-objusers-objectives {
	    border: none;
	    border-radius: 0px;
	    background-color: #ffffff;
	}*/

	.clicktoadd{
		background-color: #d5dae3;
	}

</style>
<script type="text/javascript"></script>
<script>

	import Usersapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'
	import Cockpitsapi from '@/services/api/cockpits'

	import memberslist from '@/components/members/memberslist' 
	import membersearch from '@/components/members/membersearch'
	import vueselect from '@/views/tools/vueselect'

	

	export default{
		name: 'contactsTable',
		data(){
			return{
				selectedtext:"",
				searchtext:"",
				adminrole: "Member",
				defaultrole: "Member",
				availableusers:[],
				alreadyonlist:false,
				sendaninvite :false,
				sendaninvitetouser:"",
				isactive:true,
				isadmanaged:false,
				myrole:"Member",
				mode:"idle",
				users: [],
				searchquery:""
			}
		},

		props:{
			boardusers:Array,
			canwrite:Boolean,
			team:Object,
			contact:Object,
			activestakeholder:Object,			
		},

		created(){
	  },

	  mounted() {


	  	
	  },

	  watch:{


	  },

		computed:{

			editmode(){

				let found = 0 

				for( var i in this.activestakeholder.contacts ){

					if( this.activestakeholder.contacts[i].state == 'editcontact' ){
						found += 1
					}
				}

				return found == 0 ? false : true

			},

			exemptids(){

				//console.log( this.activestakeholder.contacts, "exemptids")

				var ids = []
				for( var i in this.activestakeholder.contacts ){
					ids.push( this.activestakeholder.contacts[i]._id )
				}
				return ids
			},

			allowadduser(){
				return true
				//return this.myrole == this.adminrole ? true : false
			},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	
		    
      token(){
        return this.$store.getters.token
      },


		},

		methods:{

			handleFocus(){


				console.log('in')
			},

			handlefocusout( e ){

				let self = this

				setTimeout( function(){
					self.searchtext = ""
					self.mode = 'idle'
					self.availableusers = []
				},100)

			},

			newcontact(){

				let self = this

				this.mode = 'addnew'

				setTimeout( function(){

					//if( self.$refs ){

						console.log( $( 'input', '#search-objusers').get(0) )
						$('input', '#search-objusers').get(0).focus()
					//	self.$refs.inputinlinesearch.focus()
					//}		

				},300)

			},

			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());
    			
			},	


			userinteraction( action, user, state ){

				if( action == 'switchstate' ){
					this.switchstate( user, state )
				}

				if( action == 'savecontact' ){
					this.savecontact( user, state )
				}

				if( action == 'replacecontact' ){
					this.$emit('onupdatestakeholder', this.activestakeholder, 'replacecontact', { cid:user._id, userid:state.userid } )
				}

				if( action == 'invitecontact' ){
					this.$emit('onupdatestakeholder', this.activestakeholder, 'invitecontact', { userid:user._id, status:'invited' } )
				}

				if( action == 'removecontact' ){
					this.$emit('onupdatestakeholder', this.activestakeholder, 'removecontact', { userid:state.userid } )
				}

				if( action == 'removefromboard' ){

					this.$emit('onupdatestakeholder', this.activestakeholder, 'removefromboard', { force:true, boardid:this.activestakeholder.boardid, userid:state.userid } )
				}
				 

			},

			savecontact( user, state ){


				if( user.firstname && user.firstname != "" ){


					if( user.externalemail && user.externalemail != "" ){

						let isvalid = this.validateEmail( user.externalemail )

						if( isvalid ){

							this.$emit('onupdatestakeholder', this.activestakeholder, 'contacts', { _id:user._id, firstname:user.firstname, lastname:"" ,externalemail:user.externalemail }, function( response ){

								user.state = "idle"


							})

						}else{


							user.state = "editcontact"
							user.errormessage = "emailinvalid"

						}
						

					}else{

						this.$emit('onupdatestakeholder', this.activestakeholder, 'contacts', { _id:user._id, firstname:user.firstname, lastname:"" ,externalemail:"" }, function( response ){



							//console.log( team, 'savedwithoutemail' )

						})


						user.state = "idle"

					}


				}else{

					user.state = "editcontact"
					user.errormessage = "nameinvalid"

				}

				//@click.stop.prevent="updatestakeholder( activecontact, activestakeholder,'contacts',  )

				
			},

			switchstate( user, state ){

				for( var i in state.users ){
					if( state.users[i] ){
						state.users[i].state = 'idle'
					}
				}

				user.state = state.state

			},

			onsearchonkeyup( e ){


					if ( (e.key === 'Enter' || e.keyCode === 13) && e.target ) {

								this.addthiscontact(e.target.value)

								//this.searchtext = ""

								//this.searchtext = ""
								//this.mode = 'idle'
								
					}
			},

			oninputsubmit(){

			

				//console.log( e.key,e.keyCode,e.currentTarget.value )

				 //if (e.key === 'Enter' || e.keyCode === 13) {

				 	this.addthiscontact(this.searchtext)
				 	
				 //}

				

			},

			

			validateEmail(email) {
    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());
			},			

			inviteusertoflowe( user ){

			},

			isfloweuser( email ){

				let self = this

        Usersapi.Detailsfromemail( this.token, email )
        .then( function(response){

          if( response.data.ok ){
          	self.details = response.data.user
          }

        })
        .catch(error => console.log(error))
        .finally( () => {
        })


			},

			updatestakeholder( contact, sh, act, value ){

				 this.$emit('onupdatestakeholder', sh, act, value)
				 //this.activecontact = {}
				 contact.state = 'idle'
			},

			deselectContact( contact ){

				$('.contact-selected').removeClass('contact-selected')
				contact.state = 'idle'

			},

			selectContact( contact , ev ){


				if( contact.status == 'stakeholder' ){


					var card = ev.currentTarget

					var iseditingsomewhere = 0

					if( this.activestakeholder.contacts ){

						for( var i in this.activestakeholder.contacts ){

							if( this.activestakeholder.contacts[i] != contact && this.activestakeholder.contacts[i].state == 'editing' ){

								iseditingsomewhere += 1
								
							}

						}
					}

					if( contact.state == 'idle' && iseditingsomewhere == 0 ){

						$('.contact-selected').not(card).removeClass('contact-selected')
						$(card).toggleClass('contact-selected')

						contact.state = 'editing'


					}


				}



			},


			editcontact( cn, field, value, el ){

				var input = $('<input type="text" class="form-control"></input>')

				input.value = "xxxx"

			},

			deletecontact( sh, contact ){


			},

			addthiscontact( firstname ){

				//updatestakeholder( activecontact, activestakeholder,'contacts', { _id:activecontact._id, firstname:activecontact.firstname, lastname:activecontact.lastname ,externalemail:activecontact.externalemail } )


				this.$emit('onupdatestakeholder', this.activestakeholder, 'contacts', { _id:'createnewcid', firstname:firstname, lastname:"" ,externalemail:"" } )

				this.searchtext = ""

			},

			username(user){
				let username = ''
	          	

	          	if( user.firstname ){
	          		username += user.firstname ? user.firstname:'';
	          	}

	          	if( user.lastname ){
	          		username += user.lastname ? ` ${user.lastname}`:'';
	          	}
	          	

	          	if( user.email ){
	          		username += !username && user.email ? user.email:'';
	          	}
	          	

	          	return username
			},

			searchactive(){

				/*var teamusers = Teamsapi.Getusers( this.$store.getters.token, this.team._id )
				teamusers.then( function( response ){

					console.log( response )
				})*/
				console.log('searchactive')
			},

			onupdated(){
			}, 
			availableusersupdate( search, loading ){

		        let self = this

		        this.alreadyonlist = false

		        if( this.searchbounce ){
		          clearTimeout(this.searchbounce)
		        }

		        this.searchbounce = setTimeout( function(){

		          if( search ){
		            self.searchquery = search
		          }        

		          if( search && search.length > 1 ){

		            loading(true)


		            Cockpitsapi.Getusers( search, self.exemptids, self.orgkey, false )
		            .then( function(response){   

		              if( response.data ){

		                self.sendaninvite = response.data.sendaninvite
		                self.alreadyonlist = response.data.alreadyonlist

		                if( self.sendaninvite == true ){
		                  self.sendaninvitetouser = response.data.inviteuser
		                }                

		                self.userexists =  response.data.userexists   

		                self.availableusers = response.data.users

		                self.availableusers.find(item => item._id === self.userexists._id).userexist = true


		              //console.log(self.availableusers)            
		            }

		          })
		            .catch( function(){

		              //self.cockpitsettingsmodalshown = false
		            })
		            .finally( () => {
		              loading(false)
		              //self.ajustlengthdown()

		             //ajustlengthup
		           })

		          }else{

		            self.availableusers = []
		            //self.ajustlengthdown()
		            self.searchquery = ""

		          }

		        }, 500)
		        

		      }, 

		      inviteuser(isresend , user ){

		      	this.searchtext = ""
		      	this.searchquery = ""

		    	//alert( isresend )		    	

			   	 let self = this

		         var email = this.sendaninvitetouser

		         var str1 = 'Invite sent'

		         if( isresend ){

		              var str = ""
		              //Emailsapi.Invitationemail( user, email, context)
		              Emailsapi.Invitationemail( self.loggedinuser._id, user.email, self.orgkey)

		              /*
		              We have re-sent the invitation email to the address you indicated. Once they sign up, they will automatically have the accesses you have indicated in the members list.*/
		              str = "We have sent an invitation to the email address you indicated."
					  

		              setTimeout( function(){
		                $('.vs__search')[0].blur()
		              }, 100)

		               		               
		              bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

		         }else{		         

		         	Userapi.Registeruser( email, this.$store.getters.token , this.orgkey ).then( function(response){

			            
			            if( response.data.ok ){

			              self.userSelect( response.data.user , true, true )

			              var str = ""

			              Emailsapi.Invitationemail( self.loggedinuser._id, email, self.orgkey)

			              str = "We have sent an invitation to the email address you indicated."
						  

			              setTimeout( function(){
			                $('.vs__search')[0].blur()
			              }, 100)

			               
			               
			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})
			                

			            }else{

			              var str = "Failed to send an invitation email to the address you indicated."

			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})

			            }
			        }).catch( function( err ){

			        	console.log("rollback")

			        })

		      	}

		         
		    },

		    userselectwarningproceed(){

		    	this.addnoaccesstolist = false
		    	this.userSelect( this.activeoptionselected , true )

		    },


			userSelect( option , confirm ){
				
					if( option || confirm == true ){

						console.log( { "userid":option._id  } )

						this.$emit('onupdatestakeholder', this.activestakeholder, 'addcontact', { "cid":option._id  } )

					}

			},

		},
		components: {
			memberslist, vueselect, membersearch
			
		}

	}
</script>