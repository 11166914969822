import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

export default {

	setboard( groupname, cockpitid, trelloboardid, trellolistid , boardname, listname, token){

		//console.log( cockpitid, trelloboardid, trellolistid , token )

		return axios.get(  "/Rest/Trello?action=setboard&groupname=" + groupname + "&boardid=" + cockpitid +"&trelloboardid="+ trelloboardid +"&trellolistid=" + trellolistid + "&boardname=" + boardname + "&listname=" + listname + "&t=" + token)
	},

	bindcard( groupname, objectid, trellocardid, token){

		//console.log( cockpitid, trelloboardid, trellolistid , token )

		return axios.get(  "/Rest/Trello?action=bindcard&groupname=" + groupname + "&objectid=" + objectid +"&trellocardid="+ trellocardid + "&t=" + token)
	},


}

