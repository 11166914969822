
<script type="text/javascript"></script>

<template>

  <div>


    <b-modal id="modal-create-new-objective" v-model="createObjectiveModalActive" size="sm" @hidden="hiddenmodal" @shown="showmodal"  no-close-on-backdrop no-close-on-esc>
     <template v-slot:modal-header="{cancel}">
       <!-- <button v-if="!validforposting && !deviseDesktop" type="button" aria-label="Close" class="close" disabled style ="margin-left: auto auto auto 0;  padding:0; font-size:15px;"> Add indicator</button> -->
       <h3 class="modal-title"><span> Create new objective</span></h3>
       <a class = "close-modal dialog-close-button">
          <i class="fal fa-times fa-lg " @click="cancel"></i>
        </a>
     </template>

     <div class="form-group">
      <label>Title</label>
      <input type="text" class="form-control" name="objectivenameinline" id="objectivenameinline" placeholder="Objective name" maxlength="70" @input="newobjectivecomposing($event)" :value="newobjective" tabindex=1>
    </div>


    <template v-slot:modal-footer="{ cancel, ok }">
      <button-gruppe size="" @no ="cancel();  newobjective =''" @yes = "createnewobjective()" :disabled2 ="!(newobjective != '' && notready == false)" text1 = "Create objective"></button-gruppe>

      <p class="text-danger" v-if="objectivefailedcreate == true">Something went wrong, objective is not created. Please try again</p>
    </template>
  </b-modal>

  <div class="cardwrapper objectiveadd card row" v-if="status=='idle'"> 

  <a href="#" @click.prevent="showobjectiveadd()" class="d-flex createbutton column" >
    <div class="text-center m-auto">
      <i class="fa fa-plus-circle" style="font-size: 52px;"></i><br>
      <span style="font-size: 15px;">Add objective</span>
    </div>
  </a>
</div>




  <div class="cardwrapper objectiveadd card" v-if="status=='creating'">     

    <div class="m-3">

      <p v-show="parentpage != 'myobjectives'"> 

        <span class="iconwrapper mr-1" @click="newobjectivemode='new'"><input id="newobjnew" name="objectiveaddchoose" type="radio" value="new" v-model="newobjectivemode" checked="checked"/>
        <label class="inputradio mb-0 mr-2">Create new</label>
        </span>

        <span class="iconwrapper" @click="newobjectivemode='find'"><input id="newobjfind" name="objectiveaddchoose" type="radio" value="find" v-model="newobjectivemode"/>
        <label class="inputradio mb-0">Add existing</label>
        </span>

        <!-- <input  style="position:inherit;opacity: 1;" type="radio" name="objectiveaddchoose" value="new" checked="checked" v-model="newobjectivemode"> Create new <input  style="position:inherit;opacity: 1;" type="radio" name="objectiveaddchoose" value="find" v-model="newobjectivemode"> Add existing -->

      </p>

      <template v-if="newobjectivemode=='new'">      

      <input type="text" class="form-control mb-2" name="objectivenameinline" id="objectivenameinline" placeholder="New objective" maxlength="70" @input="newobjectivecomposing($event)" :value="newobjective" tabindex=1>
      <span class="text-danger" v-if="objectivefailedcreatereason=='adnoaccess'">Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists.</span>
      <span class="text-danger" v-if="objectivefailedcreatereason=='error'">Failed to create objective</span>
      <button-gruppe size="" @no ="cancel();  newobjective =''" @yes = "createnewobjective()" :disabled2 ="!(newobjective != '' && notready == false)" text1 = "Create objective"></button-gruppe>
      </template>

      <template v-if="newobjectivemode=='find'">

      
      <objectivesSearch @onobjectiveSelect="doobjectiveSelect" :existingids="existingids"></objectivesSearch>

      <button-gruppe size="" @no ="cancel();  newobjective =''" @yes = "addselectedobjectivetoboard()" :disabled2 ="!selectedobjective._id" text1 = "Add objective"></button-gruppe>

      </template>
    </div> 
</div>


</div>
</template>
<style type="text/css" scoped>
  #createandsearch{
    color:#000 !important;
    opacity: 1 !important; 
  }

  .vs__selected{
    display: none;
  }
  
</style>
<script>

  import Cockpitsapi from '@/services/api/cockpits'  
  import objectivesSearch from './objectivesSearch'



  import {bus} from '@/main'
  
  export default{
    name:'objectivesadd',
    props:{
      parentpage:String,
      cockpit:Object,
      token:String,
      deviseDesktop: Boolean,
      devisesmall: Boolean,
      addonly: Boolean,
      existingids:Array
    },
    data(){
      return{
        status : "idle",
        measurestype:'measure',
        newobjective : "",
        createObjectiveModalActive : false,
        availableobjectives : [],
        existingobjectiveselected :null,
        measureids : [],
        searchtext : "",
        selectvalue : "",
        notready : false,
        objectivefailedcreate : false,
        objectivefailedcreatereason : "",
        newobjectivemode:"new",
        selectedobjective:{}

        
      }
    },

    components:{ objectivesSearch },

    watch:{
      cockpit : function(e,v){

        this.measureids = e.measureids
        this.searchObjectives("")
        // console.log(e.measureids,v.measureids,"aaaa")
      }
    },

    computed:{


      organization(){
        return this.$store.state.organizations.selectedorganizations
      },

      validorg(){

        if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
          return false
        }else{
          return true
        }


      },

      loggedinuser(){
        return this.$store.getters.user
      },

      orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      }

    },

    mounted(){

      bus.$off('initobjectivesadd')
      bus.$on('initobjectivesadd', this.initcomponent)

      
    },

    methods:{    

      cancel(){

        this.notready = false
        this.objectivefailedcreate = false
        this.objectivefailedcreatereason = ""
        this.status = 'idle'
        this.newobjective = ''

      },

      newobjectivecomposing( ev ){

        this.newobjective = ev.target.value

      },

      showmodal(){
        this.$nextTick(function(){
           window.addEventListener("keyup", this.clickcreate,true);
           //console.log($('#objectivenameinline').focus())
           
        })
      },

      hiddenmodal(){
          //console.log('close', window.removeEventListener("keyup",this.clickcreate, true))
           window.removeEventListener("keyup",this.clickcreate, true);
           this.status = 'idle'
           this.notready = false
      },

      clickcreate(event){
          if (event.keyCode === 13) {
                document.getElementById('savebutton').click()
                event.preventDefault()
                
              }
      },

      doselect( selected ){
        //console.log('INPUT')
       // this.selectvalue = selected.name

        //this.newobjective = selected.name
        $('#createandsearch').val( selected.name )

      },

      searchblur(){

        this.newobjective = $('#createandsearch').val()

      },

      initcomponent(){

        console.log('init')

        let self = this

        setTimeout( function(){


          self.notready = false


        }, 50)
          
          

      },

      initcreateobjective(){

        //if( this.addonly ){
        //  this.createObjectiveModalActive = true
        //}else{
          this.status = 'createobjective'
        //}

        this.$nextTick(function(){
          $('#createandsearch').focus()

        })
        

      },

      closecreateobjective(){
        this.status='idle'; 
        this.newobjective ='';
    
      },

      showdropdownmenu(){
        this.existingobjectiveselected = null
        // var divwidth = $('.objectiveadd .form-group').width()

        // var btnwidth = $('.objectiveadd .btn-secondary').width() + $('.objectiveadd .btn-primary').width() +16*4

        // this.$nextTick(function() {
        //   $('.objectiveadd .vs__dropdown-menu').width(divwidth-btnwidth+'px')
        // })


        
      },

      searchObjectives( search){


        // loading(true)
        let self = this
        // console.log(this.cockpit.measureids)
        Cockpitsapi.Getobjectives(search, this.cockpit.measureids, this.token )
        .then( function(response){

          // console.log(response)
          if( response.data ){

            self.availableobjectives = response.data.measures
          }

        })
        .catch( function(){

            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
           // loading(false)
         })


      },

      addselectedobjectivetoboard(){

        this.existingobjectiveSelect( this.selectedobjective )
      },

      doobjectiveSelect( selected ){

        this.selectedobjective=selected

        
      },


      existingobjectiveSelect( selected ){


        if( this.cockpit.measureids.indexOf( selected._id ) == -1)
        {  

          this.cockpit.measureids.push( selected._id)
          var savedvalue = JSON.stringify( this.cockpit.measureids )       

          let self = this

         // removed "{\"added\":\"6306e68d293cc13d9c897e25\",\"boardid\":\"63058e78293cc138d0d1dbae\"}"

          Cockpitsapi.Cockpitsave( { "removed": JSON.stringify({"added":selected._id, "boardid":this.cockpit._id}), "field":"measures", "t":this.token, "value":savedvalue, "authid":this.loggedinuser._id, "cid":this.cockpit._id } )
          .then( function(response){
            // console.log(response)
            if( response.data.ok ){
              self.$emit('oncockpitupdated')
            }

          })
          .catch( function(){
              //self.cockpitsettingsmodalshown = false
            })
          .finally( () => {

            self.status='idle'; 
          })

        }else{


        }

      },

     showobjectivefind(){

        this.status='searching'
     },

     showobjectiveadd(){

      this.status='creating'
      this.$nextTick(function(){
          let obj = document.getElementById('objectivenameinline')

          if( obj ){
            obj.focus();
          }
          
      })

      /*let self = this

      /*if( this.status == 'idle' ){

      $('html').on('click',function() {
       // alert('')
                self.status = "idle"
              });

      }*/


      /*this.$emit('onbeforecreate')

      if( this.existingobjectiveselected ){

        this.status='idle'
        this.newobjective =''
        this.existingobjectiveSelect( this.existingobjectiveselected )
        this.$emit('onbeforecockpitupdated')
        this.existingobjectiveselected = null
      }else{
        this.createObjectiveModalActive = true
      }*/

    },

    createnewobjective(){

      
      this.objectivefailedcreate = false
      this.objectivefailedcreatereason = ""

      let self = this

      //console.log('newobjective',this.newobjective, this.loggedinuser._id )
      if( this.loggedinuser._id && this.newobjective != '' && this.notready == false && this.validorg ){

        this.notready = true

        this.$emit('onbeforecockpitupdated')          

        var nodes = []
        var roles = []
        roles.push(JSON.stringify( { "userid":this.loggedinuser._id, "role":"Owner"}))

        Cockpitsapi.Objectivenew( {  "context":this.orgkey, "t":this.token, "cockpitid": this.cockpit._id, "measuretype": this.measurestype, "measuredescription":"", "measureshortdescription":"", "measurename": this.newobjective, "measureindex":true, "aggregationmethod":"average", "nodes": nodes, "roles[]": roles } )
        .then( function(response){
          //console.log(response)
          if( response.data.ok ){ 

            self.$emit('oncreate')
            self.notready = false   

          }else{

            if( response.data.errors.orgrole == 'noaccess' ){

              self.objectivefailedcreate = true
              self.objectivefailedcreatereason = "adnoaccess"

            }else{

              self.objectivefailedcreate = true
              self.objectivefailedcreatereason = "error"

            }
            
          }

        })
        .catch( function(){
          self.notready = false
          self.objectivefailedcreate = true
           self.objectivefailedcreatereason = "error"
        })
        .finally( () => {

          //self.status = 'idle'
          this.newobjective = ''
          self.createObjectiveModalActive = false

             self.$emit('oncockpitupdated')

            self.notready = false

            self.status = "idle"
            //do everything
            //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
          })

      }

      //else{

        
      //}
    },
    emitfoundt(newOption){
      console.log('emit')
      this.newobjective = newOption;
    },  
  }
}

</script>