<script type="text/javascript"></script>
<template>
          <div  id="statisticspage" class="cockpitview mb-2">
            <div class ="loadingstatistics" :class = "{'hide': !loading}" style="display: none;">
              <i class="fa fa-circle-o-notch fa-spin"></i> <span> We are gathering all the stastistics this may take a bit </span>
            </div>

            <button @click="refreshdata()" class="hide">Refresh</button>
                          <!-- charts -->

            <Riskcharts :xaxissize="'14px'" v-if="cockpit.risks"></Riskcharts>

            <!-- charts -->
            <Boardcharts :xaxissize="'14px'"></Boardcharts>

             <h4 style="width: 100%;" class ="sectionheader mb-1"> <span style="background-color: #ffffff; padding-right: 20px;"> Objectives</span> 
             </h4>

             <template>

            <template>
            <div v-for="(section) in cockpitdata.sections" :key="'section_'+section._id" class="">
              
              <h4 v-if="section.objectivesdata" class="sectionhead"  style="width: 100%;"><span style="background-color: #ffffff; padding-right: 20px;" v-text="section.label"></span></h4>

               <div v-for="measure in section.objectivesdata" :key="'insection_'+measure._id">
                
                   <statistic v-if="measure.noaccess == false" :objectivedata="measure" :objectiveid="measure._id" :placeholder="measure.name" @oncolumnclick="columnclick" @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>
                   

                   <div id="statisticitem" v-if="measure.noaccess == true" > 
                    <div class="card mb-2">
                      <div class="card-body">
                        <h4> {{ measure.name }} 

                        <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                          <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                          <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                   <div v-if="measure.noaccess == false && measure.nodes.length > 0 " class="ml-5">
                    <template v-if ="!measure.noaccess && ( measure.isactive == true || ( measure.isactive == false && measure.myrole.toUpperCase() == 'OWNER' ) ) "> 
                      <div v-for="submeasureid in measure.nodes" :key="'insection_'+measure._id + '_' +submeasureid">

                      <statistic :objectiveid="submeasureid" :placeholder="submeasureid.name" @oncolumnclick="columnclick"  @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>

                      </div>
                    </template>
                    
                   </div>
                </div>
            </div>
            </template>

            
            
            <h4 class="sectionhead"  style="width: 100%;"><span style="background-color: #ffffff; padding-right: 20px;">Not grouped</span></h4>

            <div v-for="measure in cockpitdata.measuresnotinsections" :key="'notinsection_'+measure._id">
             <statistic v-if="measure.noaccess == false" :objectiveid="measure._id" @oncolumnclick="columnclick"></statistic>

             <div id="statisticitem" v-if="measure.noaccess == true" class="mb-2" > 
                    <div class="card">
                      <div class="card-body">
                        <h4> {{ measure.name }} 

                        <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                          <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                          <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                        </h4>
                      </div>
                    </div>
                  </div>



                  <div v-if="measure.noaccess == false && measure.nodes.length > 0 " class="ml-5">
                    <template v-if ="!measure.noaccess && ( measure.isactive == true || ( measure.isactive == false && measure.myrole.toUpperCase() == 'OWNER' ) ) "> 
                      <div v-for="submeasureid in measure.nodes" :key="'insection_'+measure._id + '_' +submeasureid">

                      <statistic v-if="measure.noaccess == false" :objectiveid="submeasureid" :placeholder="submeasureid.name" @oncolumnclick="ncolumnclick"  @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>

                      <div id="statisticitem" v-if="measure.noaccess == true" class="mb-2" > 
                        <div class="card">
                          <div class="card-body">
                            <h4> {{ measure.name }} 

                            <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                              <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                              <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      </div>
                    </template>
                    
                   </div>

            </div>

            </template>

               <h4 style="width: 100%;" class="mt-4 sectionheader mb-3"> <span style="background-color: #ffffff; padding-right: 20px;"> Reports</span> </h4>
              
                <div class="card" > 
                  
                  <div class="card-body" >


                     <div>
                          <b-table-lite :table-class="'tablelite'" @row-hovered="oncellhovered" @rrow-unhovered="oncellunhovered" :sticky-header="true"
      :no-border-collapse="true" hover borderless :items="nstattable" :fields="nfields">

        
                          <template #cell()="data">  
                           

                            <template>

                              <div class="ml-2 pl-1 pr-1 nowrap" style="white-space: nowrap;">
                                {{ data.value.value }}
                              </div>                             
                              

                            </template>


                          </template>
                          </b-table-lite>
                        </div>


                </div>
                </div>


          </div>



</template>

<style scoped>
       /* table { border-collapse:separate; border-top: 3px solid grey; }
        td, th {
            margin:0;
            border:3px solid grey; 
            border-top-width:0px; 
            white-space:nowrap;
        }
        div#statistics { 
            width: 600px; 
            overflow-x:scroll;  
            margin-left:5em; 
            overflow-y:visible;
            padding-bottom:1px;
        }
        .headcol {
            position:absolute; 
            width:5em; 
            left:0;
            top:auto;
            border-right: 0px none black; 
            border-top-width:3px;
            margin-top:-3px; 
        }
        .headcol:before {content: 'Row ';}*/

        .statstable td{
          width: 50px;
          text-align: right;
        }

        .sectionheader{
          font-weight: bold;
        }
</style>

<script>

  import Cockpitsapi from '@/services/api/cockpits'

  import statistic from '@/views/cockpit/components/statistic.vue'

  import {bus} from '@/main'

  import moment from 'moment';

  import Boardcharts from '@/components/boardcharts/boardcharts.vue'

  import Riskcharts from '@/components/boardcharts/riskcharts.vue'


  export default{
    name:'cockpitstatistics',
    props:{

      cockpitdata:Object
    },


    data(){
      return{
        cockpitstatistics:{},
        loading:true,
        mindate :  new Date().getTime(),
     
      }
    },
    watch:{},
    computed:{

      nstattable(){

        let toreturn = []

        if( this.cockpitstatistics && this.cockpitstatistics.newsletterdata ){

        let titles = [ "", "Views/Users", "Likes/Dislikes", "Comments"]

        for( let i=0; i < 4; i++ ){

           let row = {}

            row.label = {"value":titles[i]}

            let cols = 0

            this.cockpitstatistics.newsletterdata.forEach(function (element) {


              if( i == 0 ){
                row["column_"+cols] = { "newsletterid":element._id, "date":element.published.date , "value": moment( element.published.date ).format('YYYY-MM-DD')  }  
              }

              if( i == 1 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.statistics.viewcount == 0 ? '-': element.statistics.viewcount
                  val += "/"
                  val += element.statistics.viewcountunique == 0 ? '-': element.statistics.viewcountunique

                }               

                //row.push( val )  
                row["column_"+cols] = { "reportid":element._id, "date":element.indicatordate, "value":val}  
                 /*
                <span v-if="indicator.viewsactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                */
              }

              if( i == 2 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.statistics.numlikestotal == 0 ? '-': element.statistics.numlikestotal
                  val += "/"
                  val += element.statistics.numdislikestotal == 0 ? '-': element.statistics.numdislikestotal

                }               

                //row.push( val )  
                row["column_"+cols] = { "reportid":element._id, "date":element.indicatordate, "value":val} 
/*<span v-if="indicator.numlikesindicator.ishigh" class="notify"> <span class="heartbit"></span> <span class="point blue"></span></span>
                              </span> */
              }


               if( i == 3 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.commentscount == 0 ? '-': element.statistics.commentscount
                  

                }               

                //row.push( val )  
                row["column_"+cols] = { "reportid":element._id, "date":element.indicatordate, "value":val} 
/* 
                                <span v-if="indicator.commentactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                                </span>
                                <span v-if="!indicator">&nbsp;
                                </span> */
              }


              
              cols += 1

            })

           toreturn.push( row )

        }

        }

        return toreturn
      },

      nfields(){

        let toreturn = []

        if( this.cockpitstatistics && this.cockpitstatistics.newsletterdata ){

          toreturn.push( { key: 'label', stickyColumn: true, isRowHeader: true, label: '' } )

  //[ { key: 'label', stickyColumn: true, isRowHeader: true }, { key: 'label', stickyColumn: true, isRowHeader: true } ]
          
          let cols = 0
          this.cockpitstatistics.newsletterdata.forEach(function (element) {
            toreturn.push( { nid:element._id, key: "column_"+cols, label: '', class: 'selectablecol' } )

            cols += 1
          })

        }



        return toreturn

      },

      cockpit(){
        return this.$store.getters.GetCockpit
      },

      cockpitid(){
        return this.$route.params.cid
      },
      token(){
        return this.$store.getters.token
      },
      statisticobjectives(){
        return this.$store.getters.statisticsobjectives
      },
    },
    components:{statistic, Boardcharts, Riskcharts },
    mounted(){
      //console.log(this.$route)
      
      bus.$off('openstatisticspage')
      bus.$on('openstatisticspage',this.openstatisticspage)
      bus.$on('updatednewslettercommentscount',this.updatednewslettercommentscount)
      bus.$on('updatednewsletterlikecount',this.updatednewsletterlikecount)
      this.getstatistics()



      


    },
    methods:{

      oncellunhovered( i,o,p ){

        console.log( p.target.cells[o] )

      },

      oncellhovered( i,o,p ){

        //console.log(  )

        let element = null

        if( p.target.cells[o] ){
          element = p.target.cells[o]
          element.classList.add("rowhover");
        }

        

      },

      

      refreshdata(){

        this.getstatistics()
      },

      objectivesstatisticsload( obj ){


        //alert('x')
/*

       //alert('')
       var allindexsum = 0
       var cntr = 0 

       for( var i in obj.indicators ){

          if( obj.indicators[i].indexvalue ) {

            allindexsum += parseFloat( obj.indicators[i].indexvalue )
           // allindex.push( obj.indicators[i].indexvalue )
           cntr++
          }
       }

        console.log( allindexsum / cntr, "emit2")

        */

        //var rndnum = Math.floor(Math.random() * 10)

        //this.addrdata( rndnum )
      },

      addrdata( datetime, value ){

        if ( value == undefined ) {
           var rndnum = Math.floor(Math.random() * 10)
           value = rndnum
        }

        var data = []
        var name = ""
        var series =  this.series[0]
        name = series.name
        data = series.data

        //var item = []

       // item.push( value )
       // item.push( datetime )

        data.push( value )


        this.series = []
        this.series.push( { "name":name, "data":data} )
      },
      
      newsletterclick( nid ){

        //console.log(nid)

        // this.$emit('onopennewsletter', nid)

      },
      updatednewslettercommentscount(iid, count){
        for( var i in this.cockpitstatistics.newsletterdata){
           if(this.cockpitstatistics.newsletterdata[i]._id == iid){

              this.cockpitstatistics.newsletterdata[i].statistics.commentscount = count

            }

          }
        },
      updatednewsletterlikecount(iid, like, dislike){
        for( var i in this.cockpitstatistics.newsletterdata){
           if(this.cockpitstatistics.newsletterdata[i]._id == iid){

              this.cockpitstatistics.newsletterdata[i].statistics.numlikes  = like
              this.cockpitstatistics.newsletterdata[i].statistics.numdislikes  = dislike

            }

          }
      },
      openstatisticspage(){

        //console.log( this.cockpitdata )
        this.getstatistics()
      },  

      columnclick(evt, iid, mid, measure){

        if(!measure.noaccess){
           this.$emit('onopenmeasureinmodal', mid, iid)
            this.$router.push({query:{oid: mid, iid:iid, modal: 'yes'}})
        }
       
      },

      getstatistics(){

        let self = this

        this.cockpitstatistics = []
        this.loading = true

        // clear graph values
        this.$store.dispatch('addObjectiveToStatistics',{ objective:null, clear:true })

        Cockpitsapi.Statistics( this.cockpitid, this.token )
        .then( function(response){

          if( response.data.ok ){    

            self.cockpitstatistics = response.data.cockpit

            setTimeout( function(){
              $('[data-toggle="tooltip"]').tooltip()
            }, 200)

            self.loading = false
             
            
          }

        }).finally(()=>{
          $('td').mouseover(function(){
            let self = this
            $('*').removeClass('statstable-selected')
            var tableC =  $(this).closest( "table" )
            var tableid = tableC.attr('id')
            var table = $('#'+tableid +' td').each(function(index){
              if(self.cellIndex == this.cellIndex){
                $(this).addClass('statstable-selected')
                 // console.log('this ',self.cellIndex, ':', ' td ', this.cellIndex, ':', ' td all ', this )
              }
              
              })
            })
           $('td').mouseout(function(){
           
            $('*').removeClass('statstable-selected')
          })
        })

        Cockpitsapi.Cockpitschartdata( { "_id": this.cockpitid, "token": this.token } ).then( function(response){

          if( response.data.indicatorinweeks ){

            self.series2data = response.data.indicatorinweeks
            self.chartwarning = response.data.incompletedata
            //console.log( response.data.indicatorinweeks , "xx")
          }

      })


      },
      onopennewsletterfromcockpit(nid){

        //console.log( nid.item, nid.field )

        bus.$emit('onopennewsletterforviewing',nid,this.$route.params.cid)
      }
    }
  }
</script>