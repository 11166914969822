<template>
         		
        <div  tabindex="0" :key="uiupdate">



        	<membersearch 
		 		:addbutton="true"
		 		:mode="mode"
		 		:placeholder2="'Click to add more contacts'"
		 		@addascontact="doaddascontact"
		 		@handlefocusout="handlefocusout"
		 		@onsearchonkeyup="onsearchonkeyup"
			 	@onupdated="onupdated2" 
			 	@availableusersupdate="availableusersupdate" 
			 	@userSelect ="userSelect" 
			 	@oninviteuser ="inviteuser2" 
			 	@onsearchactive="searchactive2"
			 	:users = "users" 
			 	:availableusers="availableusers" 
			 	:alreadyonlist ="alreadyonlist"
			 	:sendaninvite="sendaninvite"
			 	:isactive ="isactive" 
			 	:adminrole="adminrole"  
			 	:myrole="myrole" 
			 	:username="username" 
			 	:isadmanaged="isadmanaged"
			 	:allowsendaninvite="false"
			 	:searchquery="searchquery"
			 	:allowadduser="allowadduser"
			 	:emptytext="'No users found. Click Add to create a new contact.'"
			 	v-if="canwrite"
			 	/>


        	<div class="vs__selected-options" v-if="!userupdate && 1==2"> 
        		<form style="background: #d5dae3; width: 360px">
        		<input maxlength="125" type="search" placeholder="Add a contact" aria-autocomplete="list" aria-labelledby="vs3__combobox" aria-controls="vs3__listbox" autocomplete="off" class="vs__search inlineinput mr-2" style="width: 293px;" v-model="contactname"><button type="submit" class="btn btn-primary btn-reglg" @click.stop.prevent="addthiscontact()">Add</button></form></div>
    

		<template v-for = "( user, key  ) in activestakeholdercontactslist" >
        	<div class = "d-flex tablerow p-1" :key="'list'+key" v-if="!user.editmode" :class="{'odd' : key % 2 == 0 }" > 
	          <div class ="d-flex align-items-center" nowrap>


	             <div v-if="canwrite" class="grouptext pl-1" > 

	             		<span>{{ user.name }}</span><br v-if="!user.userid">
	             </div> 

	             <div v-else class="grouptext pl-1 d-flex align-items-center" style="height: 38px;"> 

	             		<span>{{ user.name }}</span>
	             		<span  class="text-muted" style="max-width: 300px;display: block; line-height: 5px;">
	             		</span>

	             </div> 
	             	          
	          </div> 
	          <div nowrap class=" actions d-flex ml-auto" v-if="canwrite">
	          <div class="dropdown membersdropdown">
	             <button class="gg mebmersactionsbutton btn dd-text user_role text-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	              <span><i class="fa fa-bars text-primary2"></i> </span>
	            </button>
	            <div class="p-0 dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton"  >
	                <a class="dropdown-item" href="#" @click.prevent="editcontact(user,key)">Edit</a>
	                
	                <a class="dropdown-item actiondelete" @click.prevent="removecontact(key)"> Remove</a>
	            </div>
	            </div></div>

	      </div>

	      <form class="w-100 pt-2" :key="'form'+key" v-if="user.editmode" onsubmit="return false"> 

            <div class="mb-2">
              <input type="text" class="form-control form-control-sm" placeholder="Name" v-model="contactname" maxlength="70">
              <span class="text-danger" v-if="nameinvalid"><small>Name is required</small></span>
            </div>

            <div class="mb-2">
              <input type="text" class="form-control form-control-sm" placeholder="Email" v-model="contactemail" maxlength="140">
              <span class="text-danger" v-if="emailinvalid"><small>Email is not valid</small></span>
            </div>

            <button v-if="!userupdate && 1==2" class="btn btn-primary float-right" type="submit" @click.stop.prevent="addthiscontact()">Add</button>
            <button v-if="userupdate" class="btn btn-primary float-right" type="submit" @click.stop.prevent="updatecontact()">Update</button>

            <button v-if="editmode==true" class="btn btn-secondary float-right mr-1" type="button" @click.prevent="canceleditcontact()">Cancel</button>	
            

            <span class="clearfix"></span>
      	 </form>

  	</template>



        </div>
</template>
<style type="text/css">
	.odd{
		background: var(--N100);
	}

</style>
<script type="text/javascript"></script>
<script>

	import Cockpitsapi from '@/services/api/cockpits'

	import memberslist from '@/components/members/memberslist' 
	import membersearch from '@/components/members/membersearch'

	export default{
		name: 'contactsListTable',
		data(){
			return{
				myrole: "Admininistrator",
				adminrole: "Admininistrator",
				defaultrole: "Member",
				contactid: "",
				contactname: "",
				contactemail: "",
				emailinvalid: false,
				nameinvalid: false,
				userupdate: false,
				editmode: false,
				userindex: null,
				mode:"idle",
	        	users:[],
	        	availableusers:[],
	        	alreadyonlist:[],
	        	sendaninvite :false,
	        	isactive:true,
			 	isadmanaged:false,
			 	allowsendaninvite:false,
			 	searchquery:"",
			 	alreadyonlist:false

			}
		},

		props:{
			boardusers:Array,
			boardusers:Array,
			canwrite:Boolean,
			team:Object,
			contact:Object,
			activestakeholder:Object,
			uiupdate:Number,
			updatecontactlist:Number,
		},

		created(){
	  },

	  mounted() {	  	
	  },

	  watch:{

	  	updatecontactlist : function(){
	  		this.canceleditcontact()
	  	}

	  },

		computed:{

		exemptids(){

			let toreturn = []

			if( this.activestakeholder.contactslist ){

				let list = this.activestakeholder.contactslist


				for( var i in list ){

					if( list[i].userid ){
						toreturn.push( list[i].userid )
					}
					
				}
			}				

			return toreturn
		},

		activestakeholdercontactslist(){


			let list = this.activestakeholder.contactslist

			let toreturn = []

			for( var i in list ){

				list[i].editmode = false

				toreturn.push( list[i] )
			}

			return toreturn
		},

		allowadduser(){
			return true
			//return this.myrole == this.adminrole ? true : false
		},

		orgkey(){
		return this.$route.params.orgname ? this.$route.params.orgname : null
		},	

		token(){
		return this.$store.getters.token
		},


		},

		methods:{

			username(user){
				let username = ''
	          	

	          	if( user.firstname ){
	          		username += user.firstname ? user.firstname:'';
	          	}

	          	if( user.lastname ){
	          		username += user.lastname ? ` ${user.lastname}`:'';
	          	}
	          	

	          	if( user.email ){
	          		username += !username && user.email ? user.email:'';
	          	}
	          	

	          	return username
			},

			doaddascontact( inputtext ){

				this.contactname = inputtext
				this.addthiscontact()

			},
			handlefocusout( e ){

				let self = this

				setTimeout( function(){
					self.searchtext = ""
					self.mode = 'idle'
					self.availableusers = []
				},100)

			},
			onsearchonkeyup( e ){


					if ( (e.key === 'Enter' || e.keyCode === 13) && e.target ) {

								this.doaddascontact(e.target.value)
							
					}
			},
			onupdated2(){},
			availableusersupdate( search, loading ){

		        let self = this

		        this.alreadyonlist = false

		        if( this.searchbounce ){
		          clearTimeout(this.searchbounce)
		        }

		        this.searchbounce = setTimeout( function(){

		          if( search ){
		            self.searchquery = search
		          }        

		          if( search && search.length > 1 ){

		            loading(true)


		            Cockpitsapi.Getusers( search, self.exemptids, self.orgkey, false, "", "org" )
		            .then( function(response){   

		              if( response.data ){

		                self.sendaninvite = response.data.sendaninvite
		                self.alreadyonlist = response.data.alreadyonlist

		                if( self.sendaninvite == true ){
		                  self.sendaninvitetouser = response.data.inviteuser
		                }                

		                self.userexists =  response.data.userexists   

		                self.availableusers = response.data.users

		                self.availableusers.find(item => item._id === self.userexists._id).userexist = true


		              //console.log(self.availableusers)            
		            }

		          })
		            .catch( function(){

		              //self.cockpitsettingsmodalshown = false
		            })
		            .finally( () => {
		              loading(false)
		              //self.ajustlengthdown()

		             //ajustlengthup
		           })

		          }else{

		            self.availableusers = []
		            //self.ajustlengthdown()
		            self.searchquery = ""

		          }

		        }, 500)
		        

		      }, 
			userSelect( option , confirm ){
				
					if( option || confirm == true ){

						let name = ""

						if( option.firstname ){
							name = option.firstname
						}
						if( option.lastname ){
							name += " " + option.lastname
						}
						
						
						this.contactname = name
						this.contactemail = option.email
						this.contactid  = option._id
						this.userupdate = false
						this.savecontact()

						this.searchquery = ""

						//console.log( { "userid":, "name": , "email":  } )

						//this.$emit('onupdatestakeholder', this.activestakeholder, 'addcontact', { "cid":option._id  } )

					}

			},
			inviteuser2(){},
			searchactive2(){},


			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());

			},

			removecontact( key ){

				this.$emit('oncontactlistupdate', { "act":"removecontact", "value":{"index":key+""} } )


			},

			canceleditcontact(){

				this.closealledit()

				this.contactname = ""
				this.contactemail = ""
				this.userupdate = false
				this.editmode = false
				this.userindex = null

			},

			closealledit(){

				let list = this.activestakeholdercontactslist


				for( var i in list ){
					list[i].editmode = false
				}



			},

			editcontact( user, key ){

				this.closealledit()

				user.editmode = true

				this.editmode = true
				this.userupdate = true
				this.contactname = user.name
				this.contactemail = user.email
				this.userindex = key

			},

			updatecontact(){

				this.userupdate = true
				this.savecontact()

			},

			addthiscontact(){

				this.contactid  = self.crypto.randomUUID();
				this.userupdate = false
				this.savecontact()

			},

			savecontact(){

				this.nameinvalid = false
				this.emailinvalid = false


				if( this.contactname == "" ){
					this.nameinvalid = true
					return
				}

				if( this.contactemail != '' && !this.validateEmail( this.contactemail )  ){
					this.emailinvalid = true
					return
				}

				let proceed = false



				if( this.userupdate == true ){

					proceed = true

					this.$emit('oncontactlistupdate', { "act":"updatecontact", value:{ "index":this.userindex+"", "name":this.contactname,"email":this.contactemail, "userid":this.contactid } } )
				}else{

					console.log( 'oncontactlistupdate', { "act":"addcontact", value:{ "name":this.contactname,"email":this.contactemail, "userid":this.contactid } } )

					this.$emit('oncontactlistupdate', { "act":"addcontact", value:{ "name":this.contactname,"email":this.contactemail, "userid":this.contactid } } )

					proceed = true
				}
				
				if( proceed == true){
				//	this.canceleditcontact()
				}
				

			},

			userinteraction(){

			}
		},
		components: { memberslist,membersearch }

	}
</script>