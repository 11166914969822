import { render, staticRenderFns } from "./stakeholders.vue?vue&type=template&id=602730e2&scoped=true"
import script from "./stakeholders.vue?vue&type=script&lang=js"
export * from "./stakeholders.vue?vue&type=script&lang=js"
import style0 from "./stakeholders.vue?vue&type=style&index=0&id=602730e2&prod&lang=css"
import style1 from "./stakeholders.vue?vue&type=style&index=1&id=602730e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "602730e2",
  null
  
)

export default component.exports