<template>

	  <span>

	  	<b-modal id="modal-1" title="Connect to" v-model="showconnect" @hidden="connectwithclosed">
    		<div class="m-2">    			
    			<settrelloboard :formlabel="'Trello'" :connectgroup="'riskmanagement'" @oncardcreated="cardcreated"></settrelloboard>
    		</div>
    		<div class="m-2">    			
    			<jiraclassic :buttonmode="false" :formlabel="'Trello'" :connectgroup="'riskmanagement'" @oncardcreated="cardcreated" @onsignin="jirasignedin" @onissuecreated="issuecreated" :key="jiraclassicrender"></jiraclassic>
    		</div>

    		<template v-slot:modal-footer="{ cancel, ok }">
	       		<button type="button" class="btn btn-primary ml-2" @click="showconnect=false">Done</button>
	     	</template>
  		</b-modal>

	  	<button type="button" class="btn btn-secondary ml-2" @click="showconnect=true"><i class="fas fa-share-alt-square"></i> Connect</button>

	  	 

	  </span>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>
	
	import settrelloboard from '@/views/tools/trello/settrelloboard'	
	import jiraclassic from '@/views/tools/trello/jiraclassic'	

	export default{
		name:'connectwith',

		props:{
			//showconnect:Boolean,
			buttonmode:{
		          type:Boolean,
		          default: false
		        }
		},

		data(){
			return{
				showconnect:false,
				jiraclassicrender:0
			}
		},
		props:{
		},

		watch:{
			
		},
		mounted(){


		},
		methods:{


			issuecreated(){

				this.$emit('onissuecreated')
			},

			jirasignedin(){

				this.jiraclassicrender = this.jiraclassicrender + 1
			},

			connectwithclosed(){

				this.$store.dispatch('fechtCockpit',{cid: this.cockpit._id, orgkey: this.orgkey, token: this.token})

			},

			cardcreated( card ){

				this.$emit('oncardcreated', data )
			}
			
		},
		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},


		},
		components:{ settrelloboard, jiraclassic },

	}
	//  
</script>