<template>
	<div>

    <h4 style="width: 100%;" class ="mt-3 sectionheader"> <span style="background-color: #ffffff; padding-right: 20px;">Board status development</span> </h4>
	
  <template >
  <div id="chart">
  </div>
  <div class="text-warning ml-3" v-if="chartwarning">

    <p class="learn-licensing"><a class="link-warning asalink" href="#" @click="explaingraph=explaingraph?false:true">Graph status is incomplete <i class="icon fal fa-exclamation-triangle"></i></a></p>

    <span v-if="explaingraph">You do not have access to all the objectives on this board, so the graph may not accurately reflect the overall status of the board. To get a complete picture, you will need to request access to any objectives you do not currently have access to.</span>
  </div>
  <a href="#" @click="dump()" class="hide">Dump</a>
  </template>
	</div>


</template>

<style type="text/css">
  
  #chart .apexcharts-canvas{
    /*background-color: #000;*/
    /*background-image: url('/static/chartbg.png');*/
  }

</style>

<script>

	import Cockpitsapi from '@/services/api/cockpits'
	//import VueApexCharts from 'vue-apexcharts'
  //import ApexCharts from 'vue-apexcharts'
  import ApexCharts from 'apexcharts'
  
export default {
	name: 'boardcharts',
	components: {},
	data () {
	return {
    indicatorinweeks : [],
    explaingraph : false,
    runningchart : null,
    seriesdata:[],
    options : {          
          series: [{
          name: 'Index value',
          data: []
        }],
          chart: { events : { beforeZoom: function(chartContext, { xaxis }) {
     
     //console.log( new Date(xaxis.min), "pppppppppppppp",chartContext)

    return { xaxis

//console.log(xaxis, "pppppppppppppp",chartContext)

      /*xaxis: {
        min: timestamp,
        max: timestamp
      }*/
    } 
  } }
            ,
            dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: '#000',
        opacity: 0.35},

        background: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAFiCAYAAAD/blgfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAADvSURBVHhe7dwxSsRAGIDRmcXKyhN7COttLLzHnsDO3sLCSjyAEPdzbQWZLVzkfRB+pnkQphgISeZ2bJzR7nsuBwAUAFAAQF0IcHg9fC1W5rx9vN1Gx+s8XgvzdAstFud/2YVzAgAKACgAoACAAgAKACgAoACAAgAKACgAoACAAgAKACgAoACAAlwCMO+f7rab+THet6uxMuf2/NCrOcvZBUABAAUAFABQAEABAAUAFABQJ+AXH/38NHfj5XqMt+Nicc5tv/cI5MwAgAIACgAoAKAAgAIACgAoAKAAlwD4bx6gAIACAAoAKACg/hoY4xOUl1d89CCYdwAAAABJRU5ErkJggg==')",
          type: 'line',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom',
            offsetY: 5,
          }
        },
        tooltip: { offsetX: -1000000,  offsetY: -1000000, enabled: true, marker:false, followCursor:false, custom: function(){ return '<p style="display:none">xx</p>' }, fixed:true, position:"bottomRight" },
        dataLabels: {
          enabled: false
        },
        stroke: {
              curve: 'smooth',
              colors:['#2E477B']
            },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left',
          style: {
              fontSize:  '15px',
              fontWeight:  'normal',
              fontFamily:  'Oxygen',
              color:  '#4d515b'
            }
        },
        yaxis: {
          show:false,
          title: {
            text: '',
            
          },
          min: 0.79,
          max: 1.21
        },
        xaxis: {
          type: 'datetime', 
          labels: {
              style: {
                colors: [],
                fontSize: this.xaxissize,
              }, 
          }
        },
        grid: {
              show:false,
              row: {
                //colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //opacity: 1
              },
            },
        annotations: {
              position:'back',
              yaxis: [
              {
                  y:0.7,
                  y2:0.8,
                  label: {
                    text: ""
                  }
                },
                {

                  y: 0.79,
                  y2: 0.9,
                  opacity:1,
                  borderColor: '#FF9C9C',
                  fillColor: '#FF9C9C',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 0.9,
                  y2: 1.0,
                  opacity:1,
                  borderColor: '#FFE0A5',
                  fillColor: '#FFE0A5',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 1.0,
                  y2: 1.21,
                  opacity:1,
                  borderColor: '#7ECE7E',
                  fillColor: '#7ECE7E',
                  label: {
                    text: ''
                  }
                }
              ]
            }
        },
		series: [{
              name: "Indicators",
              data: []
          }],
    xaxiscategories : [],
	  series2data:{},
	  chartwarning:false
	}
	},
	props:{
		size:{
			type:Number,
			default:()=>250
		},
    xaxissize:{
      type:String,
      default:()=>"18px"
    },    
	},
  methods: {

    dump(){

       

       let self = this

      this.runningchart.dataURI().then(({ imgURI }) => {
        self.$emit('ondumppng', imgURI)
        //console.log( imgURI , "uri")
      })

      //


    }
  },
	mounted(){
		
		let self = this

    this.runningchart = new ApexCharts(document.querySelector("#chart"), self.options);
    this.runningchart.render();

		Cockpitsapi.Cockpitschartdata( { "_id": this.cockpitid, "token": this.token } ).then( function(response){

          if( response.data.indicatorinweeks ){

           // alert( response.data.indicatorinweeks.length )

            self.indicatorinweeks = response.data.indicatorinweeks
            //console.log( indicatorinweeks ) 

            var dataxy = []
            for( var i in  self.indicatorinweeks ){

              //dataxy.push( { x:Date.parse( indicatorinweeks[i].week), y: indicatorinweeks[i].indexvalue } )

              dataxy.push( { x:Date.parse(  self.indicatorinweeks[i].week), y:  self.indicatorinweeks[i].indexvalue } )

            }

            //self.series2data = response.data.indicatorinweeks
            self.chartwarning = response.data.incompletedata
            //console.log( response.data.indicatorinweeks , "xx")

            //self.seriesdata = dataxy

            //.push( { name:"data", data: dataxy } )
            //console.log( self.options )


           /* ApexCharts.exec('boardsdata', 'updateSeries', [{
              data: dataxy
            }], true);*/

            self.runningchart.updateSeries([{
              data: dataxy
            }])


            setTimeout( function(){
              self.dump()
            }, 500)
            


          }

      })
	},
	watch:{

      series2data : function( e ){

     //   console.log( e, ev, "z")

        var datapoints = []
        var dataxy = []
        var datasingle = []

        if( e.length > 0 ){

          //var steps = 1
         // var cntr = 3

          //if( e.length > 12 ){

          //  steps = 4
          //}

          for( var i in e ){

            var datapoint = []

            datapoint.push( Date.parse(e[i].week), e[i].indexvalue )
            datapoints.push(datapoint)
            datasingle.push( e[i].indexvalue )

           // console.log( Date.parse(e[i].week), e[i].indexvalue , "dataxy" )

            dataxy.push( { x:Date.parse(e[i].week), y:e[i].indexvalue } )

            var weekdate = new Date(e[i].week)

           // const yy = weekdate.getFullYear() // 2019
            const dd = weekdate.getDate() // 23
            const mm = weekdate.getMonth()+1

           // cntr++

            //if( cntr >= steps ){

              this.xaxiscategories.push(mm+"/"+dd )
              //cntr = 0

            //}else{

            //  this.xaxiscategories.push("")
           // }

           //console.log( steps, cntr )


            

            
          }


        }

        this.series2 = []
        this.series2.push( { name:"data", data: datapoints } )

        this.series = []
        this.series.push( { name:"data", data: dataxy } )

      }

    },
	computed:{

	warningtext(){
        return this.chartwarning ? "User does not have access to all the objectives on the board, therefore the graph may look different from other users" : ""
      },

      cockpitid(){
        return this.$route.params.cid
      },
      token(){
        return this.$store.getters.token
      },

	xaxisoptions(){
        return { 'type':'datetime' }
        //return { categories: this.xaxiscategories }
      },


	chartOptions(){

      return {
            
            chart: {
              width:'900',
              height: this.size,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar:{ show:false }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
              show:false,
              row: {
                //colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //opacity: 1
              },
            },
            xaxis: {
          type: 'datetime',
        },
            yaxis: {
              show:false,
              title: {
                text: ''
              },
              min: 0.8,
              max: 1.2
            },
            annotations: {
              yaxis: [
              {
                  y:0.7,
                  y2:0.8,
                  label: {
                    text: ""
                  }
                },
                {
                  y: 0.8,
                  y2: 0.9,
                  borderColor: '#FF0000',
                  fillColor: '#FF0000',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 0.9,
                  y2: 1.9,
                  borderColor: '#FFFF00',
                  fillColor: '#FFFF00',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 1.0,
                  y2: 1.2,
                  borderColor: '#008000',
                  fillColor: '#008000',
                  label: {
                    text: ''
                  }
                }
              ]
            }
          }
        }
	}
}
</script>
