   <template>

    <div class="col-md-6 "> 
      <div class="cardwrapper card" :id="scope + '_' + objective._id" >
       <div class="row m-2 " v-if="objective.noaccess">
         <div class="px-2 w-100  d-flex flex-column">
           <h4 class = "objectiveCardtitle d-flex align-items-center" >
             <span class = "overflow-string" style="cursor: default;">{{ objective.name }}</span>
           </h4>

           <h4>
            <span class="badge badge-light" v-if="objective.userstatus != 'pendingjoin'">No Access</span>
            <span class="badge badge-light" v-if="objective.userstatus == 'pendingjoin'">Approval pending</span>
          </h4>
        </div> 

        <div class=" ml-auto text-right"> 
         <button type="button" class="btn btn-primary btn-request float-right disabled noshadow" disabled="disabled" v-if="objective.userstatus == 'pendingjoin'">Request access</button>
         <button type="button" class="btn btn-primary btn-request float-right" @click.prevent="$emit('onrequestaccess',objective);" v-if="objective.userstatus == ''">Request access</button>

       </div>
     </div>
     <div class="" v-if="!objective.noaccess">
       <!-- objective start -->
       <div class="objective_titlewrapper d-flex">
         <div class="col-11 col-md-11 px-2 d-flex flex-column">

           <h4 class = "objectiveCardtitle d-flex align-items-center" v-on:click="onshowobjectivesdescription()" v-if="objective.description =='' ||  fulldesc == false">

             <span class = "overflow-string">{{ objective.name }}</span>                         

           </h4>



           <h4 v-on:click="onshowobjectivesdescription()" class="objectiveCardtitle d-flex align-items-center asalink" v-if="objective.description!='' && fulldesc"> 

             <span class = "overflow-string">{{ objective.name }}</span>                    
             <i class="infoicon fal fa-info-circle ml-2"></i>                          
           </h4>

           <h4 class="objectiveCardtitle align-items-center" v-if="!objective.isactive" >
             <span class="badge badge-light" >Inactive</span>               
           </h4>

         </div>

         <div class="col-1 col-md-1 px-2 text-right" style="right: 5px; top: 5px; position: absolute;" v-if="!objective.noaccess">

           <!-- objective menu starts -->
           <div class="dropleft measure_menu" v-if="usemenu">
             <a class="dropdown-toggle noicon" data-toggle="dropdown">
               <span class="far fa-ellipsis-h" :class ="{'fa-ellipsis-v':columnnumber == 1, 'fa-ellipsis-h':columnnumber == 2}" aria-hidden="true"> </span>
             </a>
             <div class="dropdown-menu dropdown-menu-right menuitems"> 
               <a v-if="activeCockpit && objective.isactive && objective.myrole == 'owner' && objective.measuretype=='measure'" class="dropdown-item" v-on:click="$emit('onstatusupdate', 'measure', objective )" title="Add new status">Add status update</a>
               <a v-if="activeCockpit && objective.isactive && objective.myrole == 'owner' && objective.measuretype=='aggregator'" class="dropdown-item" v-on:click="$emit('onstatusupdate', 'aggregator', objective )" title="Add new status">Add status update</a>
               <a v-if="activeCockpit && objective.isactive && objective.myrole == 'owner' && objective.measuretype=='measure'" class="dropdown-item" :href="'#osettings_'+objective._id" 
               v-on:click.prevent="$emit('onobjectivesettings', 'measure', objective)" title="Objective settings">Objective settings</a>
               <a v-if="objective.isactive && objective.myrole == 'owner' && objective.measuretype=='aggregator'" class="dropdown-item" :href="'#osettings_'+objective._id" 
               v-on:click.prevent="$emit('onobjectivesettings', 'aggregator', objective)" title="Objective settings">Objective settings</a>
               <a v-if="!objective.isactive && objective.myrole == 'owner'" class="dropdown-item" href="#" v-on:click="$emit('onmakeactive', 'yes', 'objective', objective._id )">Make objective active</a>
               <a v-if="objective.isactive && objective.myrole == 'owner'" class="dropdown-item" href="#" v-on:click="$emit('onmakeactive', 'no', 'objective', objective._id )">Make objective inactive</a>

               <a class="dropdown-item" @click="$emit('onmemberlistcall' , 'objective', objective._id, objective)" title="Objective settings">Objective members</a>
             </div>
           </div>
           <!-- objective menu ends -->
         </div>


       </div>


       <div class="indicator_development hasindicators" v-if="!objective.noaccess && ( objective.isactive || objective.myrole == 'owner' ) ">



         <ul class="list-group list-group-horizontal mx-4"  v-if="objective.indicators.length > 0">

           <!-- tabs loops starts -->
           <!-- data-toggle="tooltip" data-placement="top" title="09 Sep 2018" -->
           <li data-toggle="tooltip" data-placement="top" title="" data-trigger="hover" class="list-group-item indicatortab showdatetooltip" :data-original-title="indicatordate(indicator.indicatordate)" v-for="(indicator, index) in objective.indicators" v-bind:key="indicator.id" :class="{'latest-indicator': ( index + 1) == objective.indicators.length }">

             <a :class="{'active':indicator.tabactive}" 
             class="nav-link indicatorlink" 
             data-toggle="tab"
             role="tab"
             style="position: relative; width: 32px; text-align: center;"
             @click.prevent="$emit('onopenmeasureinmodal', objective._id, indicator._id)"
             >
             <span v-if="( index + 1) != objective.indicators.length" ><i class="fa fa-check green" v-if="indicator.color=='green'"></i> <i class="fa fa-times red" v-if="indicator.color=='red'" ></i></span>
             <span v-if="( index + 1) == objective.indicators.length" ><i class="fa fa-check-circle green" v-if="indicator.color=='green'"></i> <i class="fa fa-times-circle red" v-if="indicator.color=='red'" ></i></span>

             <span class="notify" v-if="indicator.statusindicator=='high'"> 
               <span class="heartbit"></span> <span class="point"></span>
             </span>
           </a>


         </li>
         <!-- tabs loops ends -->

       </ul>

     </div>


     <!-- objective end -->
   </div>

 </div>

</div>
</template>
<style type="text/css" scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 /* display: none; <- Crashes Chrome on hover */
 -webkit-appearance: none;
 margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
 -moz-appearance:textfield; /* Firefox */
}


.swipe {
 overflow: hidden;
 visibility: hidden;
 position: relative;
}

.swipe-wrap {
 overflow: hidden;
 position: relative;
}

.swipe-wrap > div {
 float: left;
 width: 100%;
 position: relative;
 overflow: hidden;
}
.indicator_development{
  overflow: hidden;
}
.indicatortab {
  border: none !important;
  padding: 0;
}
.indicatoritems{
  justify-content: right;
  padding-right: 20px;
}
.indicatorlink{
  line-height: 47px;
}
.latest-indicator a {
  font-size: 24px;  
}

a:active, a:focus, a:visited {
 outline: 0;
 -moz-outline-style: none;
}

</style>
<script>

</script>

<script type="text/javascript">
 import Cockpitsapi from '@/services/api/cockpits'
 import Swipe from'swipe-js/swipe.js'
 import {indicators,comments} from '@/components/feedback'
 
 import {bus} from '@/main'
 import moment from 'moment';
 import autosize from 'autosize'
 
 

 export default {
   name: 'objectivesCard',
   props:{
     cockpit:Object,
     objective:Object,
     loggedinuser:Object,
     token:String,
     activeCockpit:Boolean,
     usemenu:Boolean,
     useswipe:Boolean,
     fulldesc:Boolean,
     scope:String,
     feedback:String,
     perpage:Number,
     index:Number,
     columnnumber: Number

   },

   components:{indicators,comments},

   data(){
     return{

       activeobjective : {},
       errors: [],
       swiperwrapper: null,
       swiper: null,
       cockpitcursors: [],
       activetab:null,
       tables:null,
       objectivemembers : [],
       showsetaktivemodale:false,
       showinstruction : false,
       showmore : false,
       showobjectivesettings : false,
       addstatusindicator : false,
       status : { "targetvalue": "", "realizedvalue": "", "calculatedindex":1, "statusindicator":"green", "calculatedindexcolor":"", "text":"" },
       calculatedindexvalue : 0,

     }

   },
   computed:{

    orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

   },

   watch: {

    cockpit : function(e){
            //console.log(e)
          }
        },


        mounted(){

          var self = this;

          moment.locale();
          $(function () {
            $('[data-toggle="tooltip"]').tooltip()
          })

          this.loadcockpit( function(){
            if( self.objective.indicators.length > self.perpage ){
              self.objective.indicators.splice( 0, ( self.objective.indicators.length - self.perpage ));
            }
          });


       // event bus

       bus.$on('onobjectiveupdate', this.objectiveupdated );
       

     },

     methods:{
      indicatordate(indicator){
        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return moment(indicator).format("MMM Do YY")
      },

      openmeasureinmodal( objective, indicator ){
       this.$emit( 'onopenmeasureinmodal', objective, indicator)
     },

     swiperconfig( parentelement, tabpos ){

      var self = this;

      return {
       startSlide: tabpos,
       auto: 0,
       draggable: true,
       autoRestart: false,
       continuous: false,
       disableScroll: true,
       stopPropagation: true,
       callback: function(index, element) {
       },
       transitionEnd: function(index, element) {

          $('.indicatorlink.active').blur()
          //$('.indicatorlink.active').removeClass('active')

          $( "a[href='#" + self.scope + "_" + element.id + "']" ).addClass('active')
          $( "a[href='#" + self.scope + "_" + element.id + "']" ).focus()
          $(parentelement).height( $(element).height() + "px"  )

       }
       }
                
        },

                objectiveupdated( objectiveid, indicatorid ){

                 var self = this;

                 if( this.objective._id == objectiveid ){        

                   setTimeout( 

                     function(){        

                      self.loadcockpit( function(){

                        if( self.objective.indicators.length > self.perpage ){
                          self.objective.indicators.splice(0, ( self.objective.indicators.length - self.perpage ));
                        }
                      });
               /*self.loadcockpit( function(){ 

                var parent = $('#'+ self.scope + '_' +objectiveid)
                 var lis = $( '.nav-tabs .indicatortab' , parent )
                 //var li = $(e.currentTarget).parent('.indicatortab')
                 var next = $('.nav-tabs' , parent ).next('.indicatorpanels')
 
                 var tabpos = lis.length - 1
 
                 $('.nav-tabs' , parent ).addClass("opened")
 
                 $('.indicatorlink').removeClass('active')
 
                 $(lis.eq(tabpos)).find('.indicatorlink').addClass('active')
                 next.removeClass('hide')
                 
                 var parentelement = document.getElementById(next.prop("id"));
                 $(parentelement).height( $('.active', parentelement).height()+'px')
 
                 self.swiperwrapper = next.prop("id")
               
                 self.swiper = null
                 self.swiper = new Swipe( parentelement, self.swiperconfig( parentelement, tabpos) );       
                 
 
               }); */
             }, 100 )

                 }

               },


               loadcockpit( callback ){


                 var self = this;
                 var tagets=$('a[data-toggle="tab"]',"#"+ this.scope + '_' +self.objective._id);
                 var tabid = self.objective._id

                 for (var i=0; i<tagets.length;i++){

                   tagets[i.toString()].addEventListener("click", function(e) {


                    var parent = $('#' + self.scope + '_' + tabid )

                    var isopened = $(e.target).parents('.nav-tabs').hasClass("opened")


                    if(self.activetab !== null && typeof( parent.find('.indicatorlink.active').attr('href')) !== 'undefined'){
               // console.log(self.activetab)
               self.objective.indicators.find( x => x._id == parent.find('.indicatorlink.active').attr('href').slice( parent.find('.nav-link.indicatorlink.active').attr('href').lastIndexOf('_')+1, parent.find('.nav-link.indicatorlink.active').attr('href').length)).showcomments = false;

             }

              // e.preventDefault()
              if(self.activetab  == e.currentTarget){

               $(e.currentTarget).parents('.nav-tabs').removeClass("opened")
               $(self.activetab).removeClass("active") 
               
               $('#indicatorpanels_'+tabid).toggleClass('hide')
               $('.indicatorpanels_'+tabid).toggleClass('hide')

               if( isopened ){
                 $(e.target).parents('.nav-tabs').next('.tab-content').find('.active').removeClass("active")
                 e.preventDefault()
                 e.stopPropagation()
               }else{
                console.log( $(e.target).parents('.nav-tabs').next('.tab-content').find('.active'))
                $(e.target).parents('.nav-tabs').next('.tab-content').find('.active').addClass("active")

                
              }
            }else{


            }



          });

                 } 


                 this.ribbonbar();

                 if( callback ){
                   callback()
                 }

                 this.$emit('onobjectiveloaded', this.objective._id)

               },

               commentposted( indicator, comments, commenttype, iid ){          

                 if( commenttype == "comment"){

                   for( var i in indicator.comments ){
                     if( indicator.comments[i]._id == iid ){
                       indicator.comments[i].comments = comments
                     }         
                   }

                 }else{
                   indicator.comments = comments
                 }

                 this.updatepages()
               },

               loadComments(indicator){

                 let self = this 
                 Cockpitsapi.Comments( { context:this.orgkey, "iid":indicator._id, "type":"status", "token": this.token} )
                 .then( function(response){

                   if( response.data.ok && response.data.comments != null){
                     indicator.comments = response.data.comments
                     indicator.commentscount = response.data.comments.length
                   }

                 })
                 .catch(error => console.log(error))
                 .finally( () => {
                   //do everything

                   //self.loadLikes( indicator )
                 })


               },
               loadLikes(indicator){

                 let self = this 
                 Cockpitsapi.Likes( { "iid":indicator._id, "type":"status", "token": this.token } )
                 .then( function(response){

                   if( response.data.ok ){
                     indicator.likescount = response.data.numlikes
                     indicator.dislikescount = response.data.numdislikes
                     indicator.vote = response.data.hasvoted
                   }

                 })
                 .catch(error => console.log(error))
                 .finally( () => {
                   //do everything
                 })


               },



               replycomment(indicator,cid,commenttype,issilent,message){ 

                 var self = this
                 Cockpitsapi.Postcomment({ "context":this.orgkey, "cockpitid":this.cockpit._id,"iid":cid,"commenttype":commenttype,"issilent":issilent,"message":message,"t":self.token,"action": "postcomment"})
                 .then( function(response){
                   if( response.data.ok ){          
                    self.loadComments(indicator)
                  }

                })
                 .catch(error => console.log(error))
                 .finally( () => {

               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
             })

               },

               Postlike(type, object , action){

                 var self = this

                 Cockpitsapi.Issuealike( type,object._id,action, this.token, this.cockpit._id )
                 .then( function(response){
                   if( response.data.ok ){       
                    object.likescount = response.data.numlikes
                    object.dislikescount = response.data.numdislikes
                    object.vote = response.data.hasvoted
                  }

                })
                 .catch(error => console.log(error))
                 .finally( () => {

               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }

             })

               },

         ribbonbar : function(){ //counts tab, if more than 10 hide other links 

          var items = $('#' + this.scope + '_' +this.objective._id).find(".indicator_tab" );
          var perpage = this.perpage;
          var position = 0;


          if( this.objective.cursor && this.objective.indicators.length > perpage ){

           this.objective.cursor.position = this.objective.indicators.length -  perpage;
           this.objective.cursor.hasleft = true;


         }

         items.each( function( i, o ){                

           var tabs = $('.indicatortab', o )                

           if( tabs.length > perpage ){

             position = tabs.length - perpage;

             tabs.addClass("hide")
             tabs.slice( position, tabs.length ).removeClass("hide")

           }                   

         })   



       },

       moveArrow(objective, indicator, dir){ 
         objective.indicator
       },

       indicatorlinkclick : function( objective, indicator){

         this.activeobjective = objective

       },



       tabmove : function( ev, objective, moveto ){

         this.activeobjective = objective

         var perpage = this.perpage;

         if( moveto == 'left' ){
           objective.cursor.position = objective.cursor.position - perpage;

           objective.cursor.hasright = true

           if( objective.cursor.position <= 0 ){
             objective.cursor.position = 0
             objective.cursor.hasleft = false                  
           }
         }

         if( moveto == 'right' ){
           objective.cursor.position = objective.cursor.position + perpage;

           objective.cursor.hasleft = true;

           if( objective.cursor.position > objective.indicators.length ){

             objective.cursor.position = objective.indicators.length - perpage
             objective.cursor.hasright = false

           }else{

             if( (objective.cursor.position + perpage) >= objective.indicators.length  ){

               objective.cursor.position = objective.indicators.length - perpage
               objective.cursor.hasright = false
             }

           }
         }

         if( $(ev.currentTarget).parents('.indicator_tab').siblings('.indicatorpanels').prop("id") == this.swiperwrapper ){
          this.swiper.slide( objective.cursor.position,0)
                  // alert('hello')
                }             
                var parent = $(ev.currentTarget).parents('.indicator_tab')

                var tabs = $('.indicatortab', parent )                

                if( tabs.length > perpage ){

                 tabs.addClass("hide")
                 tabs.slice( objective.cursor.position, objective.cursor.position +perpage ).removeClass("hide")

               } 

             },

             cockpitactivityconfirm : function( toactive ){
               this.cockpit.isactive = toactive

               $('#cockpit_setactivity').modal('hide')

             },

             measureactivityconfirm : function( toactive ){

               this.activeobjective.isactive = toactive

               $('#objective_setactivity').modal('hide')

             },

             setmeasureactivitymodal : function( objective, toactive ){

               this.activeobjective = objective;
               
             },

             setmeasureactivity: function(){
               if(this.objective.isactive){
                 this.objective.isactive = false
                 Cockpitsapi.Makeactive({'setto':'no','cid':this.objective._id})
               }else if(!this.objective.isactive){
                 this.objective.isactive = true
                 Cockpitsapi.Makeactive({'setto':'yes','cid':this.objective._id})
               }

             },

             tabs : function(){


               var numbertabs = 7;

               var uls = $('.indicator_tab');

               uls.each( function( i,o ){
                 $( '.indicatortab', o ).slice( numbertabs ).addClass('hide')
               })

             },

             showcomments : function( indicator ){

               if( indicator.showcomments == true ){
                 indicator.showcomments = false
                 autosize()
               }else{
                 indicator.showcomments = true
               }

               

               this.$nextTick(function() {
                this.updatepages()
                autosize($('.autogrow'));
              })
             },

             show_objectivedesc(){


              //this.$em
              $('#aboutobjectivemodalpage').modal('show');              
            },

            show_objectivestatusadd(){
             this.addstatusindicator = true 
           },

           show_objectivestatusaggregatoradd(){
             $('#aggregatoraddstatus').modal('show');    
           },

           updatepages(){
             var indicatorheight = $('#indicatorpanels_'+this.objective._id+' .indicatordetails.active').height()
             $('#indicatorpanels_'+this.objective._id).height(indicatorheight +'px')
           },

           onshowobjectivesdescription(){
            this.$emit('onshowobjectivesdescription',this.objective)
          }

        },
        created () {
        },
        destroyed () {

        },
        updated(){

        }
      }

    </script>

