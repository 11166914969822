<template>
<b-modal v-model="toupgrade" id ="upgradedialog" size="sm-p" no-close-on-backdrop @hidden="onhidden" >
        <template v-slot:modal-header>
          <h3 class="modal-title">Upgrade board</h3>
          <a class = "close-modal dialog-close-button mr-2">
            <i class="fal fa-times fa-lg " @click="toupgrade = false"></i>
          </a>
        </template>
        <p>Free boards are limited to 10 members but you can upgrade this board to Pro and add unlimited members.</p>
        <p>To learn about pricing, <a href="https://flowe.io/#pricing" target="_blank" class="actionprimary">click here <i class="fal fa-external-link"></i></a></p>
        <p>Click below to send an upgrade request, and we’ll get back to you soon.</p>
        <template v-slot:modal-footer="{ cancel, ok }">
          <button-gruppe :disabled="working" text1= "Send upgrade request" @no ="toupgrade = false" @yes = "upgrademaxusers()"></button-gruppe>
        </template>
      </b-modal>
</template>

<script type="text/javascript"></script>
<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import {bus} from '@/main'
  export default{ 
    props: ['opendialog','cockpitid','token'],
    data(){
      return{
          toupgrade : false,
          working : false
        }
        },
        watch:{

          opendialog : function( e ){
           this.toupgrade = this.opendialog
          }
        },

        computed: {},
        filters: {},
        computed : {

        },
        mounted() {

          this.toupgrade = this.opendialog
        },
        methods: {

          onhidden() {

            this.$emit('onUpgrademodalhidden')
          },
          upgrademaxusers : function(){

            this.working = true

            let self = this

            Cockpitsapi.Requestupgrade( { "request":"upgrademaxusers", "cid":this.cockpitid, "t":this.token } )
            .then( function(response){

               var str = "Your upgrade request has been sent. We will get back to you shortly. Please check your email for the next steps."
               var str1 = "Request sent"
                bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

                 self.$emit('onwaitingforupgrade' )


            })

          },

        },
         components:{}
      }
</script>