<script type="text/javascript"></script>
<template>

  <tr v-if="measure">

    <template>
      
      <td class="text-center w-1">
        <div class="icon-holder">
          <span v-if="!measure.noaccess && measure.isactive == true" v-html="$options.filters.statusoptionicon(measureindicator)"></span>
          <span v-else-if="measure.isactive == false" v-html="$options.filters.statusoptionicon({color:null})" /> 
          <span v-else v-html="$options.filters.statusoptionicon({color:null})" /> 

        </div>
      </td>
      <td class="w-100">
        <span v-text="measure.name">measure name 1</span>&nbsp;
        <template v-if="!measure.isactive || measure.noaccess || measureindicator == '' ">
           <b-badge class ="ml-2" v-if="measure.noaccess"  variant="light">No access</b-badge>
          <b-badge class ="ml-2" v-else-if="!measure.isactive" variant="light"> Inactive </b-badge>
          <b-badge class ="ml-2" v-else variant="light"> No status </b-badge>
        </template>          
      </td>

    </template>

      
          

  </tr>

</template>

<script>

  import Cockpitsapi from '@/services/api/cockpits'

  import {bus} from '@/main'

	export default{
		name:'addstatustoaggregatoritem',
		props:{
			measureid:String,
		},
    filters: {
    },

		data(){
			return{
        measure:null,
        measureverified : 0,
        token:"",
        loading:true
			}
		},
		watch:{
		},
		components:{
		},
    computed:{
      
      measureindicator(){
        if( this.measure.indicator){
          return this.measure.indicator.altindicator ? this.measure.indicator.altindicator : this.measure.indicator
        } else {
          return ''
        }

        
      }
    },
		mounted(){

      //bus.$off('recalculateobjectives')
     // bus.$on('recalculateobjectives',this.calculateline())

      this.token = localStorage.token
      this.calculateline()
		},
		methods:{

      tofixednumber( value ){
       return  isNaN(parseFloat(value)) == false ? value.toFixed(2) : ""
      },

      calculateline(){

        var objectiveid = this.objectiveid

        this.isworking = true
        this.measures = []

        let self = this

        Cockpitsapi.Recalculateobjective( this.measureid, this.token )
        .then( function(response){

          if( response.data ){ 

            self.measure = response.data.measure             
            self.$emit('onitemloaded', response.data.measure )


          }else{

            //self.$emit('onitemloadederror')

          }

          

        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false
            self.$emit('onitemloadederror')
          })
        .finally( () => {
        })

      }

		}
	}
</script>