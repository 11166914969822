<template>
  <div class="mb-2">

  <vueselect id="search-objusers-objectives" :options="availableobjectives"  placeholder="Search for an objective..." label="name" :resetOnOptionsChange="true" :clearSearchOnSelect="false" :closeOnSelect="true"  @search="availableobjectivesupdate" @input="objectiveSelect">

          <div class="my-auto noresult" slot="no-options">
            <span>Search for existing objectives.</span> 
          </div>

         
          <template #search="{ attributes, events }">
            <input
              class="form-control inlineinput"
              v-bind="attributes"
              v-on="events"
              ref="inputinlinesearch"
              v-model="selectedtext"
              
            />
          </template>


      </vueselect>
  </div>
</template>

<style scoped>


  #search-objusers, #search-objusers-objectives {
    border-radius: 0px;
    background-color: #ffffff;
    border-radius: 0.25rem;
    font-size: 1em;
    font-weight: 400;
    color: #313338 !important;
    padding-bottom: 0;
    padding-top: 0;
    border: none !important;
  }

  .selectedlabel{
    padding: 5px;
  }



  

</style>

<script>

  import vueselect from '@/views/tools/vueselect'
  import Cockpitsapi from '@/services/api/cockpits'

  export default{
    name: 'objectivesCardworking',
    data(){
      return{
        availableobjectives:[],
        object:{},
        selectedobjective:{},
        selectedtext:""
      }
    },

    props:{
      existingids:Array
    },

    computed:{

      token(){
        return this.$store.getters.token
      },

      orgkey(){
        return this.$route.params.orgname
      },

    },

    mounted(){

    },

    methods:{


      clearselected(){
          
          this.selectedtext = ""
          //this.selectedobjective = {}
          this.$emit('onobjectiveSelect', {} )       

      },

      objectiveSelect( selected ){       

       // = "xxxxxxxxxxxx"

       // let self = this

       //this.searchtext = "sadasdasdsa"

        if( selected ){

          this.selectedobjective = selected         

          //this.$refs.inputinlinesearch.value = selected.names
          this.selectedtext = selected.name
         // this.selectedobjective = selected

          this.availableobjectives = []
          //alert(   )
          this.$emit('onobjectiveSelect', selected )       

        }     
      },

      availableobjectivesupdate( search, loading ){

        loading(true)

        let self = this


        Cockpitsapi.Getobjectives( search, this.existingids, this.token, this.orgkey )
        .then( function(response){


          if( response.data && response.data.measures ){
            self.availableobjectives = response.data.measures
            //  console.log(self.availableobjectives)     
          }

        })
        .catch( function(){
        })
        .finally( () => {
          loading(false)
        })

      },


    },

    components:{ vueselect},

  }
</script>