<script type="text/javascript"></script>

<template>
	<b-modal v-model="isshown" id="requestingaccess" modal-class="" size="md" @hidden="onmodalhidden" @shown="onmodalshown" no-close-on-backdrop no-close-on-esc >
		<template v-slot:modal-header>
        	<h3 class="modal-title">Request access</h3>
        	<a class = "close-modal dialog-close-button mr-2">
      <i class="fal fa-times fa-lg " @click="isshown = false"></i>
    </a>      	</template> 


		<p>Click the button below to send a request for access to the objective owner. You can also include an optional message explaining why you need access.</p>

		<div class="form-group">
			<textarea name="requestaccessmessage" rows="3" class="form-control" style="overflow: hidden; overflow-wrap: break-word; resize: none;" v-model="requestmessage"></textarea>
		</div>

		<template v-slot:modal-footer="{ cancel, ok }">
			<button-gruppe default ="click" text1= "Send request" @no ="cancel" @yes = "requestingaccess" :loading="isworking"></button-gruppe>



		</template>

	</b-modal>
</template> 

<script>

	import Cockpitsapi from '@/services/api/cockpits'
	import autosize from 'autosize'
	import {bus} from '@/main'

	export default{
		name:'requestingaccess',
		props:{
			shown:Boolean,
			objectiveid:String,
			userid:String,
			cockpitid:String,
		},

		data(){
			return{
				isshown: false,
				requestmessage:"",
				token:"",
				isworking : false
			}
		},

		watch:{

			shown : function( e ){
				this.isshown = e
				
			},
			requestmessage : function(){
				autosize($('textarea'));
			}
		},
		methods:{

			requestingaccess(){

				this.isworking = true

				let self = this 

				var bodytext =  this.requestmessage.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2')


				Cockpitsapi.Requestaccess( this.cockpitid, this.objectiveid, this.token, bodytext  )
				.then( function(response){

					if( response.data.ok ){

						self.$emit( 'onrequestsubmitted', self.objectiveid )
						self.isshown = false

						self.isworking = false
						
						//indicator.comments = response.data.comments
						//indicator.commentscount = response.data.comments.length
					}

				})
				.catch(error => console.log(error))
				.finally( () => {

					self.isworking = false
					
					var str = 'Your request was successfully sent. Please wait for an owner to respond.'
					var str1 = 'Message sent'
                	bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

				})


			},

			cancel(){
				this.isshown = false

			},

			onmodalshown(){
				this.token = localStorage.token
				this.requestmessage = ""
			},

			onmodalhidden(){
				this.$emit('onmodalhidden')
			}
		}
	}
	</script>