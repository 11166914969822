import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl

//axios.defaults.headers.common['flowe-event-id'] = this.$store.getters.logeventId

export default {

	setproject( groupname, cockpitid, jiraprojectid, jiraprofileid , projectname, profilename, token){

		//console.log( cockpitid, trelloboardid, trellolistid , token )

		return axios.get(  "/Rest/Jira?action=setproject&groupname=" + groupname + "&boardid=" + cockpitid +"&jiraprojectid="+ jiraprojectid +"&jiraprofileid=" + jiraprofileid + "&projectname=" + projectname + "&profilename=" + profilename + "&t=" + token)
	},

	issuecreated( groupname, cockpitid, riskid, issueid, payload, token ){

		//let groupname = "issues"

		return axios.get(  "/Rest/Jira?action=newissue&groupname=" + groupname + "&boardid=" + cockpitid + "&objectid="+ riskid + "&valueid=" + issueid +"&payload="+ payload + "&t=" + token)

	},

	getissues( boardid, token ){

		return axios.get(  "/Rest/Jira?action=getallissues&t="+token+"&boardid="+boardid)
	}


}

