<script type="text/javascript"></script>
<template>
	<div class="dashboarditemwrapper">

    <router-link :id="intro" :to="orgname + '/boards/'+cockpit._id+'/objectives'" class="card-block clearfix" tabindex="0">

      <div class="card dashboarditem my-3" >
        <div class="d-flex flex-row">
          <div class="accent"></div> 
          <div class="align-self-center w-100">
           <div class="align-self-center  w-auto card-title"> 
            <span style ="word-break: break-word;">{{cockpit.name}}</span>
           <!--  <span v-if="cockpit.IsNew" class="badge badge-light badge-info ml-2">New</span> -->
          </div>

          <div class="activity row justify-content-md-center">
            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Comments">
              <span><i class="fas fa-comments"></i>{{cockpit.comments}}</span>
            </p>
            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Likes">
              <span><i class="fas  fa-thumbs-up"></i>{{cockpit.likes}}</span>
            </p>
            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Dislikes">
              <span><i class="fas  fa-thumbs-down"></i>{{cockpit.dislikes}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </router-link>                          
</div>
</template>

<script>
  import introJs from 'intro.js/intro.js'

  import {bus} from '@/main'
  export default{

    name: 'cockpitCard',
    props:{
      cockpit:{},
      index: Number
    },
    data() {
     return{
      intro: (this.index == 0 ) ? 'refcockpit':'',
      newCockpit: true,
    }
  },
  mounted(){
    
      this.$nextTick(function(){
        if(this.index == 0){
          bus.$emit('startintro','boards');
        }
      })
    
  },
  methods:{

  },
  computed:{
    token(){
      return localStorage.token
    },
    orgname(){
          if( this.$route.params.orgname ){
            return "/" + this.$route.params.orgname
          }else{
            return "/"
          }
        }
  }
}
</script>